import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'jalali-moment';
import { ApplicationDateFormatTypeEnum } from 'src/app/core/models/service-constant';

@Pipe({name: 'shamsiDateFormat'}) 
export class ShamsiDateFormatPipe implements PipeTransform {

    transform(input: Date ,formatTyp: number): string{

        var result = "";

        let jalaliDate = moment(new Date(input)).locale('fa');

        if(formatTyp == ApplicationDateFormatTypeEnum.DateOnly)
            result = jalaliDate.format('YYYY/M/D');
        else if(formatTyp == ApplicationDateFormatTypeEnum.DateAndTime)
            result = jalaliDate.format('YYYY/M/D hh:mm:ss');
        else if(formatTyp == ApplicationDateFormatTypeEnum.DateWithDayOfWeek)
            result = `${jalaliDate.format('dddd')} ${jalaliDate.format('YYYY/M/D')}`;   
        else if(formatTyp == ApplicationDateFormatTypeEnum.DayOfWeek)
            result = jalaliDate.format('dddd');   
            else if(formatTyp == ApplicationDateFormatTypeEnum.TimeOnly)
            result = jalaliDate.format('hh:mm:ss');                          
        else
            result = jalaliDate.format('YYYY/M/D');                   

        return result;
    }
}