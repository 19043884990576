import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/core/modal.service';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { Office } from 'src/app/shared/models/Office';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { OfficeEditComponent } from '../office-edit/office-edit.component';
import { Subscription } from 'rxjs';
import { ToolbarService } from 'src/app/core/services/toolbar.service';
import { OfficeCreateComponent } from '../office-create/office-create.component';

@Component({
  selector: 'app-office-index',
  templateUrl: './office-index.component.html',
  styleUrls: ['./office-index.component.css']
})
export class OfficeIndexComponent implements OnInit {

  alert: AlertModel;  

  providerId:number;

  providerOffices:Array<Office>;

  addButtonClickSubscription: Subscription;
  backButtonClickSubscription: Subscription;
  menuFilterClickSubscription: Subscription;
  menuSortClickSubscription: Subscription;

  constructor(private authService: AuthService 
             ,private router: Router
             ,private toolbarService: ToolbarService
             ,private modalService: ModalService ) {}

  ngOnInit() {

    this.toolbarService.setTitle('آرایشگاه ها');
    this.toolbarService.setBackButtonVisible(true);
    this.toolbarService.setNewButtonVisible(true);
    // this.toolbarService.setMenuFilterVisibleIND(true);
    // this.toolbarService.setMenuSortVisibleIND(true);

    this.addButtonClickSubscription = this.toolbarService.newItemClick$.subscribe(event=>{
      this.addButtonClicked(event);
    });

    this.backButtonClickSubscription = this.toolbarService.backButtonClick$.subscribe(event=>{
      this.backButtonClicked(event);
    });    

    this.menuFilterClickSubscription = this.toolbarService.menuFilterClick$.subscribe(event=>{
      this.menuFilterClicked(event);
    });     
    
    this.menuSortClickSubscription = this.toolbarService.menuSortClick$.subscribe(event=>{
      this.menuSortClicked(event);
    });      

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.providerId = userData.ProviderID;    
      this.getAllProviderOffices();  
    }
    else{

      this.authService.redirectToLoginPage();
      return;
    }   
  }

  ngOnDestroy() {

    this.toolbarService.setTitle('');    
    this.toolbarService.setBackButtonVisible(false);
    this.toolbarService.setNewButtonVisible(false);
    this.toolbarService.setMenuFilterVisibleIND(false);
    this.toolbarService.setMenuSortVisibleIND(false);

    this.addButtonClickSubscription.unsubscribe();
    this.backButtonClickSubscription.unsubscribe();
    this.menuFilterClickSubscription.unsubscribe();
    this.menuSortClickSubscription.unsubscribe();
  }

  backButtonClicked(event: Event){
    this.router.navigate(['/dashboard']);
  }

  menuFilterClicked(event: Event){
    console.log("filter clicked");
  }

  menuSortClicked(event: Event){
    console.log("sort clicked");
  }  

  getAllProviderOffices(){
    
    var req=new ServiceRequest();
    req.data.sn = ServiceConstant.getProviderOfficesSN;
    req.data.p1 = this.providerId;
    req.data.p2 = "";//searchTitle;
    // req.data.p3 = sortType;
     //req.data.p4 = 0;//startRowIndex;
     //req.data.p5 = 10;//recordCount;

    this.authService.consumeService(req).subscribe((res)=>{
              
      if(!res){
        return;
      }

      var serviceResponse = res || {} as ServiceResponse;

      if(serviceResponse.hasError != true)
      {
        this.providerOffices = serviceResponse.data || {} as Array<Office>;
      }
    });     
  }

  editOffice(officeId: number){

    this.modalService.show(
      OfficeEditComponent,this.providerOffices.find(x => x.OfficeID == officeId))
      .then(confirmed => {
        if (confirmed) {

          console.log("confirmed");
        } else {

          //this.confirmResult = "Canceled!";    
          console.log("cancel");      
        }
      });
  }  

  confirmDeleteRecord(officeId: number){

    this.modalService.show(
      ConfirmModalComponent,
      {
        title: "سوال", message: "آیا از حذف رکورد اطمینان دارید؟"
      })
      .then(confirmed => {
        if (confirmed) {

          this.deleteProviderOffice(officeId);
        } else {

          //this.confirmResult = "Canceled!";          
        }
      });
  }  

  deleteProviderOffice(officeId: number){

    if(isNaN(this.providerId) || this.providerId <=0){
      this.authService.redirectToLoginPage();
      return;
    }   
    
    if(isNaN(officeId) || officeId <=0){
      return;
    }     

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.deleteOfficeSN;
      serviceRequest.data.p1 = officeId;

  
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;
        
        if(srvResponse.hasError != true){

          const itemIndex = this.providerOffices.findIndex(obj=> obj["OfficeID"] == officeId);
          this.providerOffices.splice(itemIndex, 1);
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });      

  }

  addButtonClicked(event: Event){

    this.modalService.show(OfficeCreateComponent)
      .then(confirmed => {
        if (confirmed) {

          // console.log("confirmed");
          // console.log(newServiceProvider);

          this.getAllProviderOffices();

        } else {   
        }
      });
  }

  redirectToDetailView(officeId: number){
    this.router.navigate(['\officedetail' ,{id: officeId}]);
  }
}
