import { Component, OnInit, OnDestroy } from "@angular/core";
import { Campaign } from "src/app/shared/models/Campaign";
import { ToolbarService } from "src/app/core/services/toolbar.service";

import { Subscription } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "app-campaign-index",
  templateUrl: "./campaign-index.component.html",
  styleUrls: ["./campaign-index.component.css"],
})
export class CampaignIndexComponent implements OnInit, OnDestroy {
  detailMode: boolean = false;
  selectedCampaign: Campaign;
  selectedCampaignAlreadyJoinedIND: boolean;

  customTextButtonClickSubscription: Subscription;
  backButtonClickSubscription: Subscription;
  menuFilterClickSubscription: Subscription;
  menuSortClickSubscription: Subscription;

  constructor(private toolbarService: ToolbarService, private router: Router) {}

  ngOnInit() {
    this.toolbarService.setTitle("کمپین ها");
    this.toolbarService.setCustomTextButtonVisible(false, "");
    this.toolbarService.setBackButtonVisible(true);
    this.toolbarService.setMenuFilterVisibleIND(false); //Badan bayad true shavad
    this.toolbarService.setMenuSortVisibleIND(false); //Badan bayad true shavad

    this.customTextButtonClickSubscription = this.toolbarService.customTextButtonClick$.subscribe(
      (event) => {
        this.customTextButtonClicked(event);
      }
    );

    this.backButtonClickSubscription = this.toolbarService.backButtonClick$.subscribe(
      (event) => {
        this.backButtonClicked(event);
      }
    );

    this.menuFilterClickSubscription = this.toolbarService.menuFilterClick$.subscribe(
      (event) => {
        this.menuFilterClicked(event);
      }
    );

    this.menuSortClickSubscription = this.toolbarService.menuSortClick$.subscribe(
      (event) => {
        this.menuSortClicked(event);
      }
    );
  }

  ngOnDestroy() {
    this.toolbarService.setTitle("");
    this.toolbarService.setBackButtonVisible(false);
    this.toolbarService.setNewButtonVisible(false);
    this.toolbarService.setMenuFilterVisibleIND(false);
    this.toolbarService.setMenuSortVisibleIND(false);

    this.backButtonClickSubscription.unsubscribe();
    this.menuFilterClickSubscription.unsubscribe();
    this.menuSortClickSubscription.unsubscribe();
  }

  selectCampaign(campaign: Campaign, myCampaignIND: boolean) {
    this.selectedCampaign = campaign;
    this.selectedCampaignAlreadyJoinedIND = myCampaignIND;
    this.detailMode = true;
    this.toolbarService.setTitle("جزییات کمپین");

    let customTextBtnCaption = "عضویت";
    if (myCampaignIND) {
      customTextBtnCaption = "لغو";
    }
    this.toolbarService.setCustomTextButtonVisible(true, customTextBtnCaption);
    //this.toolbarService.setCaptionCustomTextButtonVisible(customTextBtnCaption);
  }

  backButtonClicked(event: Event) {
    if (this.detailMode) {
      this.detailMode = !this.detailMode;
      this.toolbarService.setTitle("کمپین ها");
      this.toolbarService.setCustomTextButtonVisible(false, "");
    } else {
      this.router.navigate(["/dashboard"]);
    }
  }

  customTextButtonClicked(event: Event) {
    if (this.selectedCampaignAlreadyJoinedIND) {
      alert("cancel registration");      
    } else {
      alert("register");
    }
  }

  menuFilterClicked(event: Event) {
    console.log("filter clicked");
  }

  menuSortClicked(event: Event) {
    console.log("sort clicked");
  }
}
