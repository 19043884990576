import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";

import { ApiConfigService } from "./api-config.service";
import { APP_CONFIG, IAppConfig } from "./app.config";
import { TokenStoreService } from "./token-store.service";
import { ServiceRequest } from "../models/service-request";
import { ServiceConstant } from "../models/service-constant";
import { ServiceResponse } from "../models/service-response";
import { ProviderInfo } from "src/app/shared/models/provider-info";
import { WeekDayViewModel } from "src/app/shared/models/WeekDayViewModel";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private authStatusSource = new BehaviorSubject<boolean>(false);
  authStatus$ = this.authStatusSource.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private apiConfigService: ApiConfigService,
    private tokenStoreService: TokenStoreService
  ) {
    this.updateStatusOnPageRefresh();
  }

  loginByVerifyCode(modelItem: ServiceRequest): Observable<ServiceResponse> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http
      .post(`${this.appConfig.apiEndpoint}`, modelItem, { headers: headers })
      .pipe(
        map((response: any) => {
          //this.tokenStoreService.setRememberMe(credentials.rememberMe);
          this.tokenStoreService.setRememberMe(true);
          if (!response) {
            console.error(
              "There is no `{'" +
                this.apiConfigService.configuration.accessTokenObjectKey +
                "':'...','" +
                this.apiConfigService.configuration.refreshTokenObjectKey +
                "':'...value...'}` response after login."
            );
            this.authStatusSource.next(false);

            var svcRes = new ServiceResponse();
            svcRes.hasError = true;
            return svcRes;
          }

          return response;
        }),
        catchError((error: HttpErrorResponse) => throwError(error))
      );
  }

  getProviderInfoAndSetLocalData(
    providerId: number,
    token?: string
  ): Observable<ProviderInfo> {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });

    if (!token) {
      token = this.getCurrentUserToken();
    }

    if (token) {
      headers = headers.set("Authorization", "Bearer " + token);
    }

    var serviceRequest = new ServiceRequest();
    serviceRequest.data.sn = ServiceConstant.getProviderServiceSN;
    serviceRequest.data.p1 = providerId;

    return this.http
      .post(`${this.appConfig.apiEndpoint}`, serviceRequest, {
        headers: headers,
      })
      .pipe(
        map((response: any) => {
          if (!response) {
            console.error(
              "There is no `{'" +
                this.apiConfigService.configuration.accessTokenObjectKey +
                "':'...','" +
                this.apiConfigService.configuration.refreshTokenObjectKey +
                "':'...value...'}` response after login."
            );
            this.authStatusSource.next(false);
            return null;
          }

          var srvResponse = response || ({} as ServiceResponse);

          if (srvResponse.hasError != true && srvResponse.data != null) {
            var providerInfo = srvResponse.data || ({} as ProviderInfo);
            if (providerInfo.ProviderID > 0) {
              this.tokenStoreService.setRememberMe(true);
              this.tokenStoreService.setUserData(providerInfo);
              this.authStatusSource.next(true);
              return providerInfo;
            } else return null;
          } else return null;
        }),
        catchError((error: HttpErrorResponse) => throwError(error))
      );
  }

  logout(redirectToLogin: boolean): void {
    this.tokenStoreService.deleteUserDataTokens();
    this.authStatusSource.next(false);

    if (redirectToLogin === true) this.router.navigate(["/login"]);
  }

  isAuthUserLoggedIn(): boolean {
    return this.tokenStoreService.hasStoredValidUserData();
  }

  getAuthUser(): ProviderInfo | null {
    if (!this.isAuthUserLoggedIn()) {
      return null;
    }

    return this.tokenStoreService.getUserData();
  }

  getCurrentUserToken(): string | null {
    if (!this.isAuthUserLoggedIn()) {
      return null;
    }

    return this.tokenStoreService.getUserData().Token;
  }

  private updateStatusOnPageRefresh(): void {
    this.authStatusSource.next(this.isAuthUserLoggedIn());
  }

  consumeService(request: ServiceRequest): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    var userToken = this.getCurrentUserToken();
    if (userToken) {
      headers = headers.set("Authorization", `Bearer ${userToken}`);
      console.log("header set : " + userToken);
    }

    console.log(headers);

    return this.http
      .post(`${this.appConfig.apiEndpoint}`, request, { headers: headers })
      .pipe(
        map((response: any) => {
          if (!response) {
            console.error(
              "There is no `{'" +
                this.apiConfigService.configuration.accessTokenObjectKey +
                "':'...','" +
                this.apiConfigService.configuration.refreshTokenObjectKey +
                "':'...value...'}` response after."
            );
            return false;
          }

          return response;
        }),
        catchError((error: HttpErrorResponse) => throwError(error))
      );
  }

  uploadImageService(
    srvRequest: ServiceRequest,
    fileToUpload: File
  ): Observable<any> {

   
    var userToken = this.getCurrentUserToken();
 
    let formData: FormData = new FormData();

    for (const key in srvRequest) {
      if (srvRequest.hasOwnProperty(key)) {
        formData.append(key, JSON.stringify(srvRequest[key]));
      }
    }

    formData.append("files", fileToUpload);

    return this.http
      .post(`${this.appConfig.apiEndpoint}`, formData, { headers: { 'Authorization': `Bearer ${userToken}` } })
      .pipe(
        map((response: any) => {
          if (!response) {
            console.error(
              "There is no `{'" +
                this.apiConfigService.configuration.accessTokenObjectKey +
                "':'...','" +
                this.apiConfigService.configuration.refreshTokenObjectKey +
                "':'...value...'}` response after."
            );
            return false;
          }

          return response;
        }),
        catchError((error: HttpErrorResponse) => throwError(error))
      );
  }

  redirectToLoginPage() {
    this.router.navigate(["/login"]);
  }
}
