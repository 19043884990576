import { Client } from './Client';
import { ProviderInfo } from './provider-info';
import { ProviderService } from './ProviderService';
import { PromoCode } from './promo-code';
import { ProviderCalendarEvent } from './provider-calendar-event';
import { BookService } from './book-service';

export class Book{
    constructor(){}

    BookID: number;
    ClientID: number;
    ProviderCalendarEventID: number;
    ProviderID: number;
    PromoCodeID: number;
    ClientNote: string;
    CreateDateTime: number;
    BookStatus: number;
    NormalPrice: number;
    TotalDiscountPrice: number;
    ProviderDiscountPrice: number;
    AppDiscountPrice: number;
    TotalPrice: number;
    DefaultPaymentType: number;
    ActualPaymentType: number;
    PaymentDateTime: number;
    BookNumber: string;
    AppIncomePrice: number;
    ProviderIncomePrice: number;


    Client: Client;
    BookServices: Array<BookService>;
    ProviderCalendarEvent: ProviderCalendarEvent;
    Provider: ProviderInfo;
    PromoCode: PromoCode;
    Services: Array<ProviderService>;
}