import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'timeFormat'}) 
export class TimeFormatPipe implements PipeTransform {

    transform(input: number): string{

        var hh =Math.floor(input/60);
        var mm = Math.floor(input - (hh * 60));
    
        return `${('0' + hh).slice(-2)}:${('0' + mm).slice(-2)}`;
    }
}