import { Component, OnInit, Input } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ServiceConstant } from "src/app/core/models/service-constant";
import { AuthService } from "src/app/core/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ServiceRequestParam } from "src/app/core/models/service-request-param";
import { ServiceRequest } from "src/app/core/models/service-request";
import { ServiceResponse } from "src/app/core/models/service-response";
import { ProviderLoginResult } from "src/app/shared/models/provider-login-result";
import { PersianNumberService } from "src/app/core/services/persian-number.service";

@Component({
  selector: "app-confirm-login",
  templateUrl: "./confirm-login.component.html",
  styleUrls: ["./confirm-login.component.css"],
})
export class ConfirmLoginComponent implements OnInit {
  @Input() mobileNumber: string;

  alert: AlertModel;
  serviceRequestParam: ServiceRequestParam = { sn: "", p1: "", p2: "" };

  constructor(
    private authService: AuthService,
    private persianNumberService: PersianNumberService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      // Defaults to 0 if no query param provided.
      this.serviceRequestParam.p1 = +params["mobileNumber"] || "";

      if (
        !this.serviceRequestParam.p1 ||
        String(this.serviceRequestParam.p1).length < 10
      )
        this.router.navigate(["/login"]);
    });
  }

  submitForm(form: NgForm) {
    if (form.valid != true) return;

    var serviceRequest = new ServiceRequest();
    serviceRequest.data.sn = ServiceConstant.verifyloginByCodeServiceSN;
    serviceRequest.data.p1 = "00" + String(this.serviceRequestParam.p1);
    serviceRequest.data.p2 = String(this.serviceRequestParam.p2);
    serviceRequest.data.p2 = this.persianNumberService.toEnglish(
      this.serviceRequestParam.p2
    );

    this.authService.loginByVerifyCode(serviceRequest).subscribe((response) => {
      if (!response) {
        this.alert = {
          type: "danger",
          message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید.2222",
        };
        return;
      }

      console.log(response);

      const loginResult = response || ({} as ServiceResponse);

      if (loginResult.hasError != true && loginResult.data != null) {
        var providerInfo = loginResult.data || ({} as ProviderLoginResult);

        if (providerInfo.ProviderID > 0 && providerInfo.Token) {
          this.authService
            .getProviderInfoAndSetLocalData(
              providerInfo.ProviderID,
              providerInfo.Token
            )
            .subscribe((res) => {
              if (!res) {
                this.alert = {
                  type: "danger",
                  message:
                    "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید.10011",
                };
                return;
              }

              if (res.ProviderID > 0 && this.authService.isAuthUserLoggedIn()) {
                this.router.navigate(["/dashboard"]);
              } else
                this.alert = {
                  type: "danger",
                  message:
                    "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید.01",
                };
            });
        } else
          this.alert = {
            type: "danger",
            message:
              "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید.111",
          };
      } else {
        if (loginResult.error.message)
          this.alert = { type: "danger", message: loginResult.error.message };
        else
          this.alert = {
            type: "danger",
            message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید.02",
          };
      }
    });
  }
}
