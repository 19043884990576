import { Component, OnInit } from '@angular/core';
import { ProviderInfo } from 'src/app/shared/models/provider-info';
import { AuthService } from 'src/app/core/services/auth.service';
import { NgForm } from '@angular/forms';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';

@Component({
  selector: 'app-activation-request',
  templateUrl: './activation-request.component.html',
  styleUrls: ['./activation-request.component.css']
})
export class ActivationRequestComponent implements OnInit {

  alert: AlertModel;  

  description: string;  

  args:ProviderInfo;

  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

  close: (val?: any) => void;

  submitForm(form:NgForm){

    if(form.valid != true)
      return; 

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.sendProviderActivationRequestSN;

      serviceRequest.data.p1 = this.args.ProviderID;
      serviceRequest.data.p2 = this.description;
  
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;

        if(srvResponse.hasError != true){

          this.args.IsInActivationRequest = true;
          this.close(true);          
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });
  }
}
