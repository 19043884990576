import { Component, OnInit, Input } from '@angular/core';
import { Provider } from '@angular/compiler/src/compiler_facade_interface';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { Bank } from 'src/app/shared/models/Bank';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { NgForm } from '@angular/forms';
import { ProviderInfo } from 'src/app/shared/models/provider-info';

@Component({
  selector: 'app-edit-bank',
  templateUrl: './edit-bank.component.html',
  styleUrls: ['./edit-bank.component.css']
})
export class EditBankComponent implements OnInit {

  alert: AlertModel;  

  bankId: number;
  accountNumber: string;

  bankList: Array<Bank>;

  args:ProviderInfo; //bookid

  constructor(private authService: AuthService,private router: Router) { }

  ngOnInit() {

    this.bankId= this.args.BankID;
    this.accountNumber = this.args.AccountNumber;

    this.fillLookupBankList();
  }

  fillLookupBankList(){ 

    var srvRequest = new ServiceRequest();
    srvRequest.data.sn = ServiceConstant.getBanksSN;

    this.authService.consumeService(srvRequest).subscribe((res)=>{
              
      if(!res){        
        return;
      }

      var serviceResponse = res || {} as ServiceResponse;
      if(serviceResponse.hasError != true)
      {
        this.bankList = serviceResponse.data || {} as Array<Bank>;
      }

    });

  }; 

  close: (val?: any) => void;

  submitForm(form:NgForm){

    if(form.valid != true)
      return; 

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.updateProviderBankInfoSN;

      serviceRequest.data.p1 = this.args.ProviderID;
      serviceRequest.data.p2 = this.bankId;
      serviceRequest.data.p3 = this.accountNumber;
  
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;

        if(srvResponse.hasError != true){

          this.args.BankID = this.bankId;
          this.args.BankName = this.bankList.find(x=>x.BankID == this.bankId).Name;
          this.args.AccountNumber = this.accountNumber;
          this.close(true);
          
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });
  }

}
