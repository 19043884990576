import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard-app',
  templateUrl: './dashboard-app.component.html',
  styleUrls: ['./dashboard-app.component.css']
})
export class DashboardAppComponent implements OnInit {

  constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) { 

    iconRegistry.addSvgIcon(
      "service-icon",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/service.svg")
    );

    iconRegistry.addSvgIcon(
      "book-icon",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/bookmark.svg")
    );
    
    iconRegistry.addSvgIcon(
      "calendar-icon",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/calendar.svg")
    );
    
    iconRegistry.addSvgIcon(
      "calendarevent-icon",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/calendarevent.svg")
    );    

    iconRegistry.addSvgIcon(
      "campaigning-icon",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/campaigning.svg")
    );   
    
    iconRegistry.addSvgIcon(
      "finance-icon",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/finance.svg")
    );    
    
    iconRegistry.addSvgIcon(
      "house-icon",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/house.svg")
    );    
    
    iconRegistry.addSvgIcon(
      "message-icon",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/message.svg")
    );      
    
    iconRegistry.addSvgIcon(
      "instagram-icon",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/instagram.svg")
    );         
  }

  ngOnInit() {
  }

}
