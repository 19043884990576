import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DashboardModule } from './dashboard/dashboard.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { UserProfileModule } from './user-profile/user-profile.module';
import { ProviderBookModule } from './provider-book/provider-book.module';
import { CalendarEventModule } from './calendar-event/calendar-event.module';
import { ProviderOfficeModule } from './provider-office/provider-office.module';
import { ProviderServiceModule } from './provider-service/provider-service.module';
import { ProviderCalendarModule } from './provider-calendar/provider-calendar.module';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { CampaignModule } from './campaign/campaign.module';
import { FinancialModule } from './financial/financial.module';
import { ProviderMessageModule } from './provider-message/provider-message.module';


@NgModule({
  declarations: [
    AppComponent    
  ],
  imports: [
    BrowserModule,    
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule.forRoot(),        
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),

    DashboardModule,  
    CampaignModule,    
    FinancialModule,  
    ProviderMessageModule,
    UserProfileModule,
    ProviderServiceModule,      
    ProviderBookModule,
    CalendarEventModule,
    ProviderCalendarModule,      
    ProviderOfficeModule,
    AuthenticationModule,
    
    AppRoutingModule,
      
    
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
