import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent  implements OnInit{

  constructor() {     }

  args: {
    title: string;
    message: string;
    confirmtext: string;
    canceltext: string;
    hideCancelButton: boolean;
    hideConfirmButton: boolean;
  };

  close: (val?: any) => void;

  ngOnInit() {

    if(this.args.hideCancelButton == undefined || this.args.hideCancelButton== null){
      this.args.hideCancelButton = false;
    }

    if(this.args.hideConfirmButton == undefined || this.args.hideConfirmButton== null){
      this.args.hideConfirmButton = false;
    }  

    if(this.args.confirmtext == null || this.args.confirmtext == ""){

      this.args.confirmtext = "بلی";
    }

    if(this.args.canceltext ==null || this.args.canceltext == ""){

      this.args.canceltext = "خیر";
    }          
  }

}
