import { Component, OnInit, Input } from "@angular/core";
import { Campaign } from "src/app/shared/models/Campaign";
import {
  ServiceConstant,
  ApplicationDateFormatTypeEnum,
} from "src/app/core/models/service-constant";
import { ServiceRequest } from "src/app/core/models/service-request";
import { AuthService } from "src/app/core/services/auth.service";
import { ServiceResponse } from "src/app/core/models/service-response";

@Component({
  selector: "app-campaign-detail",
  templateUrl: "./campaign-detail.component.html",
  styleUrls: ["./campaign-detail.component.css"],
})
export class CampaignDetailComponent implements OnInit {
  providerId: number;
  @Input() campaignInfo: Campaign;
  @Input() campaignAlreadyJoinedIND: boolean;
  applicationDateFormatTypeEnum = ApplicationDateFormatTypeEnum;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    var userData = this.authService.getAuthUser();

    if (
      userData != null &&
      userData.ProviderID > 0 &&
      this.campaignAlreadyJoinedIND == true
    ) {
      this.providerId = userData.ProviderID;
      this.getProviderCampaignDetail();
    }
  }

  getProviderCampaignDetail() {
    var serviceRequest = new ServiceRequest();
    serviceRequest.data.sn = ServiceConstant.getProviderCampaignDetailSN;
    serviceRequest.data.p1 = this.campaignInfo.CampaignID;
    serviceRequest.data.p2 = this.providerId;

    this.authService.consumeService(serviceRequest).subscribe((response) => {
      if (!response) {
        return;
      }

      var serviceResponse = response || ({} as ServiceResponse);
      if (serviceResponse.hasError != true) {
        this.campaignInfo = serviceResponse.data || ({} as Campaign);
      }
    });
  }
}
