import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProviderMessageAppComponent } from './provider-message-app/provider-message-app.component';
import { MessageIndexComponent } from './components/message-index/message-index.component';
import { AuthGuard } from '../core/services/auth.guard';
import { MessageDetailComponent } from './components/message-detail/message-detail.component';

const routes: Routes = [
  { path: '', redirectTo: 'messageindex' ,pathMatch: 'full' },
  {
    path: "", component: ProviderMessageAppComponent,
    children: [
      { path: "messageindex", component: MessageIndexComponent },
      { path: "messagedetail", component: MessageDetailComponent },
    ]
    ,canActivate: [ AuthGuard ]
    ,canActivateChild: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProviderMessageRoutingModule { }
