import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ToolbarService {
  private title = new BehaviorSubject<String>("");
  private title$ = this.title.asObservable();

  private newButtonVisibleIND = new BehaviorSubject<boolean>(false);
  public newButtonVisibleIND$ = this.newButtonVisibleIND.asObservable();
  private newItemClick = new Subject<Event>();
  newItemClick$ = this.newItemClick.asObservable();

  private editButtonVisibleIND = new BehaviorSubject<boolean>(false);
  public editButtonVisibleIND$ = this.editButtonVisibleIND.asObservable();
  private editButtonClick = new Subject<Event>();
  editButtonClick$ = this.editButtonClick.asObservable();

  private customTextButtonCaption = new BehaviorSubject<string>("");
  public customTextButtonCaption$ = this.customTextButtonCaption.asObservable();

  private customTextButtonVisibleIND = new BehaviorSubject<boolean>(false);
  public customTextButtonVisibleIND$ = this.customTextButtonVisibleIND.asObservable();
  private customTextButtonClick = new Subject<Event>();
  customTextButtonClick$ = this.customTextButtonClick.asObservable();

  private backButtonVisibleIND = new BehaviorSubject<boolean>(false);
  private backButtonVisibleIND$ = this.backButtonVisibleIND.asObservable();
  private backButtonClick = new Subject<Event>();
  backButtonClick$ = this.backButtonClick.asObservable();

  private menuFilterVisibleIND = new BehaviorSubject<boolean>(false);
  private menuFilterVisibleIND$ = this.menuFilterVisibleIND.asObservable();
  private menuFilterClick = new Subject<Event>();
  menuFilterClick$ = this.menuFilterClick.asObservable();

  private menuSortVisibleIND = new BehaviorSubject<boolean>(false);
  private menuSortVisibleIND$ = this.menuSortVisibleIND.asObservable();
  private menuSortClick = new Subject<Event>();
  menuSortClick$ = this.menuSortClick.asObservable();

  constructor() {}

  setTitle(title: String) {
    this.title.next(title);
  }

  getTitle(): Observable<String> {
    return this.title$;
  }

  //================================

  setBackButtonVisible(visibleIND: boolean) {
    this.backButtonVisibleIND.next(visibleIND);
  }

  getBackButtonVisible(): Observable<boolean> {
    return this.backButtonVisibleIND$;
  }

  backButtonClicked(event) {
    this.backButtonClick.next(event);
  }

  //==================================

  setNewButtonVisible(visibleIND: boolean) {
    this.newButtonVisibleIND.next(visibleIND);
  }

  getNewButtonVisible(): Observable<boolean> {
    return this.newButtonVisibleIND$;
  }

  newButtonClicked(event) {
    this.newItemClick.next(event);
  }

  //============================
  setMenuFilterVisibleIND(visibleIND: boolean) {
    this.menuFilterVisibleIND.next(visibleIND);
  }

  getMenuFilterVisibleIND(): Observable<boolean> {
    return this.menuFilterVisibleIND$;
  }

  menuFilterClicked(event) {
    this.menuFilterClick.next(event);
  }

  //============================
  setMenuSortVisibleIND(visibleIND: boolean) {
    this.menuSortVisibleIND.next(visibleIND);
  }

  getMenuSortVisibleIND(): Observable<boolean> {
    return this.menuSortVisibleIND$;
  }

  menuSortClicked(event) {
    this.menuSortClick.next(event);
  }

  //==================================

  setEditButtonVisible(visibleIND: boolean) {
    this.editButtonVisibleIND.next(visibleIND);
  }

  getEditButtonVisible(): Observable<boolean> {
    return this.editButtonVisibleIND$;
  }

  editButtonClicked(event) {
    this.editButtonClick.next(event);
  }

  //==================================

  setCustomTextButtonVisible(visibleIND: boolean, caption: string) {
    this.customTextButtonVisibleIND.next(visibleIND);
    this.customTextButtonCaption.next(caption);
  }

  getCustomTextButtonVisible(): Observable<boolean> {
    return this.customTextButtonVisibleIND$;
  }

  getCustomTextButtonCaption(): Observable<string> {
    return this.customTextButtonCaption$;
  }

  customTextButtonClicked(event) {    
    this.customTextButtonClick.next(event);
  }
}
