import { Injectable } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modalService: NgbModal) {}

  show(component: any, args?: any, options?: any): Promise<any> {
    return new Promise(resolve => {
      
      let result: any;
      options = options || {};

      const modalRef = this.modalService.open(component ,{
        beforeDismiss: () => false
      });
      
      
      // const sub = this.modalService..dismissAll().onHidden.subscribe(() => {
      //   sub.unsubscribe();
      //   resolve(result);
      // });

      modalRef.componentInstance.args = args;
      modalRef.componentInstance.options = options;
      modalRef.componentInstance.close = (val?: any) => {

        //console.log(val);
        result = val;
        resolve(result);
        modalRef.close();
      };

    });
  }
}
