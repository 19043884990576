import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-provider-office-app',
  templateUrl: './provider-office-app.component.html',
  styleUrls: ['./provider-office-app.component.css']
})
export class ProviderOfficeAppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
