import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProviderServiceAppComponent } from './provider-service-app/provider-service-app.component';
import { ServiceIndexComponent } from './components/service-index/service-index.component';
import { ServiceDetailComponent } from './components/service-detail/service-detail.component';
import { ServiceCreateComponent } from './components/service-create/service-create.component';
import { ServiceEditComponent } from './components/service-edit/service-edit.component';
import { AuthGuard } from '../core/services/auth.guard';
import { SelectServiceCategoryComponent } from './components/select-service-category/select-service-category.component';

const routes: Routes = [
  { path: '', redirectTo: 'providerserviceindex' ,pathMatch: 'full' },
  {
    path: "", component: ProviderServiceAppComponent,
    children: [
      { path: "providerserviceindex", component: ServiceIndexComponent },
      { path: "providerservicedetail", component: ServiceDetailComponent },
      { path: "providerservicecreate", component: ServiceCreateComponent },
      { path: "providerserviceedit", component: ServiceEditComponent },
      { path: "selectservicecategory", component: SelectServiceCategoryComponent },
    ]
    ,canActivate: [ AuthGuard ]
    ,canActivateChild: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProviderServiceRoutingModule { }
