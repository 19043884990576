import { Component, OnInit } from '@angular/core';
import { ProviderMessage } from 'src/app/shared/models/provider-message';
import { AuthService } from 'src/app/core/services/auth.service';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant, ApplicationDateFormatTypeEnum } from 'src/app/core/models/service-constant';

@Component({
  selector: 'app-message-detail',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.css']
})
export class MessageDetailComponent implements OnInit {

  alert: AlertModel;  
  providerId:number;
  args:ProviderMessage;
  applicationDateFormatTypeEnum=ApplicationDateFormatTypeEnum;
  
  constructor(private authService: AuthService) {  }

  ngOnInit() {

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.providerId = userData.ProviderID;  
      this.getProviderMessageDetail();
    }
    else{

      this.authService.redirectToLoginPage();
      return;
    }
  } 

  getProviderMessageDetail(){
    
    var req=new ServiceRequest();
    req.data.sn = ServiceConstant.getProviderMessageDetailSN;
    req.data.p1 = this.providerId;
    req.data.p2 = this.args.MessageID;

    this.authService.consumeService(req).subscribe((res)=>{
              
      if(!res){
        return;
      }

      var serviceResponse = res || {} as ServiceResponse;

      if(serviceResponse.hasError != true)
      {
        this.args = serviceResponse.data || {} as ProviderMessage;      
      }
    });     
  }   

  close: (val?: any) => void;
}