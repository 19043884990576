import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NgbPaginationModule, NgbModalModule, NgbModule, NgbTimepickerModule, NgbCarouselModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    NgbModalModule.forRoot(),
    NgbPaginationModule,//.forRoot(),
    NgbAlertModule,//.forRoot(),
    NgbButtonsModule,//.forRoot()    
    NgbCarouselModule,
    NgbTimepickerModule,
    NgbRatingModule,
  ],
  exports: [
    NgbModalModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgbButtonsModule,
    NgbCarouselModule,
    NgbTimepickerModule,
    NgbRatingModule,
  ]
})
export class SharedBootstrapModule { }