import { Component, OnInit } from '@angular/core';
import { ServiceConstant } from 'src/app/core/models/service-constant';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { Book } from 'src/app/shared/models/Book';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { PaymentTypeEnum } from 'src/app/shared/enums/payment-type';
import { MapLocation } from 'src/app/shared/models/MapLocation';

@Component({
  selector: 'app-book-done',
  templateUrl: './book-done.component.html',
  styleUrls: ['./book-done.component.css']
})
export class BookDoneComponent implements OnInit {

  alert: AlertModel;  

  paymentTypeEnum = PaymentTypeEnum;
  args:Book; //bookid

  constructor(private authService: AuthService,private router: Router) { }

  ngOnInit() {}

  close: (val?: any) => void;

  setBookDone(paymentType:number){

    if(isNaN(paymentType) ||paymentType<=0 )
      return;        

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.providerBookDoneSN;

      serviceRequest.data.p1 = this.args.ProviderID;
      serviceRequest.data.p2 = this.args.BookID;
      serviceRequest.data.p3 = paymentType;
      serviceRequest.data.p4 = new MapLocation();




      // this.args.ActualPaymentType = this.paymentTypeEnum.Wallet;// paymentType;
      // this.close(true);
      // return;



  
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;

        if(srvResponse.hasError != true){

          this.args.ActualPaymentType = paymentType;
          this.close(true);
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });
  }
}
