import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { ProviderCalendar } from 'src/app/shared/models/ProviderCalendar';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/core/modal.service';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant, WeekDaysConstant } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { CalendarEditComponent } from '../calendar-edit/calendar-edit.component';
import { ToolbarService } from 'src/app/core/services/toolbar.service';
import { Subscription } from 'rxjs';
import { CalendarCreateComponent } from '../calendar-create/calendar-create.component';

@Component({
  selector: 'app-calendar-index',
  templateUrl: './calendar-index.component.html',
  styleUrls: ['./calendar-index.component.css']
})
export class CalendarIndexComponent implements OnInit ,OnDestroy {

  alert: AlertModel;  

  weekDayList: any;

  providerId:number;
  officeId:number;
  providerCalendars:Array<ProviderCalendar>;

  addButtonClickSubscription: Subscription;
  backButtonClickSubscription: Subscription;
  menuFilterClickSubscription: Subscription;
  menuSortClickSubscription: Subscription;

  constructor(private authService: AuthService 
             ,private router: Router
             ,private toolbarService: ToolbarService
             ,private modalService: ModalService ) {
               
              this.weekDayList = WeekDaysConstant.weekdaysData;
              }

  ngOnInit() {

    this.toolbarService.setTitle('ساعت کاری');
    this.toolbarService.setBackButtonVisible(true);
    this.toolbarService.setNewButtonVisible(true);
    // this.toolbarService.setMenuFilterVisibleIND(true);
    // this.toolbarService.setMenuSortVisibleIND(true);

    this.addButtonClickSubscription = this.toolbarService.newItemClick$.subscribe(event=>{
      this.addNewButtonClicked(event);
    });

    this.backButtonClickSubscription = this.toolbarService.backButtonClick$.subscribe(event=>{
      this.backButtonClicked(event);
    });    

    this.menuFilterClickSubscription = this.toolbarService.menuFilterClick$.subscribe(event=>{
      this.menuFilterClicked(event);
    });     
    
    this.menuSortClickSubscription = this.toolbarService.menuSortClick$.subscribe(event=>{
      this.menuSortClicked(event);
    });      

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.providerId = userData.ProviderID;
      if(userData.Offices.length > 0){
        
        this.officeId = userData.Offices[0].OfficeID;
        this.getAllProviderCalendars();
      }        
      else
      {
        this.alert = {type: "danger" ,message: "لطفا ابتدا آرایشگاه را از قسمت پروفایل کاربر انتخاب نمائید."};
        return;
      }       
    }
    else{

      this.authService.redirectToLoginPage();
      return;
    }   
  }

  ngOnDestroy() {

    this.toolbarService.setTitle('');    
    this.toolbarService.setBackButtonVisible(false);
    this.toolbarService.setNewButtonVisible(false);
    this.toolbarService.setMenuFilterVisibleIND(false);
    this.toolbarService.setMenuSortVisibleIND(false);

    this.addButtonClickSubscription.unsubscribe();
    this.backButtonClickSubscription.unsubscribe();
    this.menuFilterClickSubscription.unsubscribe();
    this.menuSortClickSubscription.unsubscribe();
  }

  backButtonClicked(event: Event){
    this.router.navigate(['/dashboard']);
  }

  menuFilterClicked(event: Event){
    console.log("filter clicked");
  }

  menuSortClicked(event: Event){
    console.log("sort clicked");
  }
  

  getAllProviderCalendars(){
    
    var req=new ServiceRequest();
    req.data.sn = ServiceConstant.getAllProviderCalendarsSN;
    req.data.p1 = this.providerId;
    req.data.p2 = this.officeId;
    // req.data.p3 = weekDay;
    // req.data.p4 = starRowIndex;
    // req.data.p5 = recordCount;

    this.authService.consumeService(req).subscribe((res)=>{
              
      if(!res){
        return;
      }

      var serviceResponse = res || {} as ServiceResponse;

      if(serviceResponse.hasError != true)
      {
        this.providerCalendars = serviceResponse.data || {} as Array<ProviderCalendar>;
        this.providerCalendars.sort((a,b)=> a.WeekDay > b.WeekDay ? 1 : -1);
      }
    });     
  }

  addNewButtonClicked(event: Event){

    this.modalService.show(CalendarCreateComponent)
      .then(confirmed => {
        if (confirmed) {

          // console.log("confirmed");
          // console.log(newServiceProvider);

          this.getAllProviderCalendars();

        } else {

          //this.confirmResult = "Canceled!";    
          console.log("cancel");      
        }
      });
  }

  redirectToEditView(providerCalendarID: number){
    //this.router.navigate(['\calendaredit' ,{id: providerCalendarID}]);

    this.modalService.show(
      CalendarEditComponent,this.providerCalendars.find(x => x.ProviderCalendarID == providerCalendarID))
      .then(confirmed => {
        if (confirmed) {

          console.log("confirmed");
        } else {

          //this.confirmResult = "Canceled!";    
          console.log("cancel");      
        }
      });

  }  

  confirmDeleteRecord(providerCalendarID: number){

    this.modalService.show(
      ConfirmModalComponent,
      {
        title: "سوال", message: "آیا از حذف اطلاعات اطمینان دارید؟"
      })
      .then(confirmed => {
        if (confirmed) {

          this.deleteProviderCalendar(providerCalendarID);
        } else {

          //this.confirmResult = "Canceled!";          
        }
      });


  }  

  deleteProviderCalendar(providerCalendarID: number){

    if(isNaN(this.providerId) || this.providerId <=0){
      this.authService.redirectToLoginPage();
      return;
    }    

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.deleteProviderCalendarSN;
      serviceRequest.data.p1 = providerCalendarID;

  
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;
        
        if(srvResponse.hasError != true){

          const itemIndex = this.providerCalendars.findIndex(obj=> obj["ProviderCalendarID"] == providerCalendarID);
          this.providerCalendars.splice(itemIndex, 1);
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });      

  }
}
