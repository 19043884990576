import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { AuthenticationAppComponent } from './authentication-app/authentication-app.component';
import { LoginComponent } from './components/login/login.component';
import { ConfirmLoginComponent } from './components/confirm-login/confirm-login.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    AuthenticationAppComponent,
    LoginComponent,
    ConfirmLoginComponent    
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule.forRoot(),
    AuthenticationRoutingModule
  ]
})
export class AuthenticationModule { }
