import { Component, OnInit, OnDestroy } from '@angular/core';
import { FinancialTransaction } from 'src/app/shared/models/financial-transaction';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { ToolbarService } from 'src/app/core/services/toolbar.service';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant, ApplicationDateFormatTypeEnum } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';

@Component({
  selector: 'app-financial-index',
  templateUrl: './financial-index.component.html',
  styleUrls: ['./financial-index.component.css']
})
export class FinancialIndexComponent implements OnInit ,OnDestroy {

  alert: AlertModel;  

  providerId:number;
  financialTransactions:Array<FinancialTransaction>;

  backButtonClickSubscription: Subscription;

  applicationDateFormatTypeEnum=ApplicationDateFormatTypeEnum;

  constructor(private authService: AuthService 
             ,private router: Router             
             ,private toolbarService: ToolbarService ) {}

  ngOnInit() {

    this.toolbarService.setTitle('مالی');
    this.toolbarService.setBackButtonVisible(true);

    this.backButtonClickSubscription = this.toolbarService.backButtonClick$.subscribe(event=>{
      this.backButtonClicked(event);
    });         

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.providerId = userData.ProviderID;  
      this.getProviderFinancialTransactionس();
    }
    else{

      this.authService.redirectToLoginPage();
      return;
    }
  }

  ngOnDestroy() {

    this.toolbarService.setTitle('');    
    this.toolbarService.setBackButtonVisible(false);
    this.toolbarService.setNewButtonVisible(false);
    this.toolbarService.setMenuFilterVisibleIND(false);
    this.toolbarService.setMenuSortVisibleIND(false);

    this.backButtonClickSubscription.unsubscribe();
  }

  backButtonClicked(event: Event){
    this.router.navigate(['/dashboard']);
  }

  getProviderFinancialTransactionس(){
    
    var req=new ServiceRequest();
    req.data.sn = ServiceConstant.getProviderFinancialTransactionsSN;
    req.data.p1 = this.providerId;
    // req.data.p2 = sortType;
    // req.data.p3 = filterType;
    // req.data.p4 = startRowIndex;
    // req.data.p5 = recordCount;

    this.authService.consumeService(req).subscribe((res)=>{
              
      if(!res){
        return;
      }

      var serviceResponse = res || {} as ServiceResponse;

      if(serviceResponse.hasError != true)
      {
        this.financialTransactions = serviceResponse.data || {} as Array<FinancialTransaction>;      
      }
    });     
  } 

}
