import { WeekDayViewModel } from "src/app/shared/models/WeekDayViewModel";

export class ServiceConstant {
  static readonly loginServiceSN = "1";
  static readonly verifyloginByCodeServiceSN = "2";
  static readonly getProviderInfoServiceSN = "3";
  static readonly sendProviderActivationRequestSN = "4";
  static readonly updateProviderProfileInfoSN = "5";
  static readonly updateProviderBankInfoSN = "6";
  static readonly getOfficesSN = "9";
  static readonly getProviderOfficesSN = "10";
  static readonly getAllProviderServicesSN = "11";
  static readonly getServiceCategoriesSN = "12";
  static readonly createServiceSN = "13";
  static readonly updateServiceSN = "14";
  static readonly deleteServiceSN = "15";
  static readonly getAllProviderBooksSN = "16";
  static readonly getProviderServiceSN = "17";
  static readonly getProviderBookCancelReasonsSN = "18";
  static readonly providerBookCancelSN = "19";
  static readonly getAllProviderCalendarsSN = "20";
  static readonly createProviderCalendarSN = "21";
  static readonly deleteProviderCalendarSN = "22";
  static readonly editProviderCalendarSN = "23";
  static readonly addProviderHeaderPictureSN = "24";
  static readonly deleteProviderHeaderPictureSN = "25";
  static readonly setDefaultProviderHeaderPictureSN = "26";
  static readonly updateProviderProfilePictureSN = "27";
  static readonly getCitiesByProvinceIDSN = "31";
  static readonly getBanksSN = "32";
  static readonly updateServiceHeaderPictureSN = "33";
  static readonly createOfficeSN = "34";
  static readonly updateOfficeSN = "35";
  static readonly deleteOfficeSN = "36";
  static readonly addOfficeHeaderPictureSN = "37";
  static readonly deleteOfficeHeaderPictureSN = "38";

  static readonly getAllProviderCalendarEventsSN = "41";
  static readonly createProviderCalendarEventSN = "42";
  static readonly deleteProviderCalendarEventSN = "43";

  static readonly getProviderMessagesSN = "44";
  static readonly getProviderMessageDetailSN = "45";
  static readonly getProviderFinancialTransactionsSN = "46";

  static readonly getActiveCampaignsSN = "47";
  static readonly getProviderCampaignsSN = "48";
  static readonly getProviderCampaignDetailSN = "49";
  static readonly providerJoinTheCampaignSN = "50";
  static readonly providerLeavesTheCampaignSN = "51";

  static readonly providerBookDoneSN = "52";
  static readonly updateProviderCalendarEventSN = "54";
  static readonly deleteProviderMessageSN = "55";
  static readonly getAllOfficeReviewsSN = "57";
  static readonly getAllProviderReviewsSN = "58";
  static readonly getAllServiceReviews = "59";
  static readonly getServiceByServiceIDSN = "60";
  static readonly getOfficeByOfficeIDSN = "61";
  static readonly loginByProviderIdServiceSN = "62";
  static readonly deleteServicePictureSN = "64";

  static readonly getProviderServicesByCampaignServicesSN = "66";
}

export class WeekDaysConstant {
  static readonly weekdaysData: Array<WeekDayViewModel> = [
    { DayNo: 1, DayTitle: "شنبه" },
    { DayNo: 2, DayTitle: "یک شنبه" },
    { DayNo: 3, DayTitle: "دوشنبه" },
    { DayNo: 4, DayTitle: "سه شنبه" },
    { DayNo: 5, DayTitle: "چهارشنبه" },
    { DayNo: 6, DayTitle: "پنج شنبه" },
    { DayNo: 7, DayTitle: "جمعه" },
  ];
}

export enum ApplicationDateFormatTypeEnum {
  DateOnly = 1,
  DateAndTime = 2,
  DateWithDayOfWeek = 3,
  DayOfWeek = 4,
  TimeOnly = 5,
}
