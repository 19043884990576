import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProviderOfficeAppComponent } from './provider-office-app/provider-office-app.component';
import { OfficeIndexComponent } from './components/office-index/office-index.component';
import { OfficeCreateComponent } from './components/office-create/office-create.component';
import { AuthGuard } from '../core/services/auth.guard';
import { OfficeEditComponent } from './components/office-edit/office-edit.component';
import { OfficeDetailComponent } from './components/office-detail/office-detail.component';
import { OfficeLocationComponent } from './components/office-location/office-location.component';

const routes: Routes = [
  { path: '', redirectTo: 'office' ,pathMatch: 'full' },
  {
    path: "", component: ProviderOfficeAppComponent,
    children: [
      { path: "office", component: OfficeIndexComponent },
      { path: "officedetail", component: OfficeDetailComponent },
      { path: "officecreate", component: OfficeCreateComponent },
      { path: "officeedit", component: OfficeEditComponent },
      { path: "officelocation", component: OfficeLocationComponent },
    ]
    ,canActivate: [ AuthGuard ]
    ,canActivateChild: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProviderOfficeRoutingModule { }
