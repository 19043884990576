import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClientReview } from 'src/app/shared/models/ClientReview';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant, ApplicationDateFormatTypeEnum } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {

  providerId:number;
  clientReviews:Array<ClientReview>;

  applicationDateFormatTypeEnum=ApplicationDateFormatTypeEnum;
  
  constructor(private authService: AuthService
    ,private config: NgbRatingConfig) { 

      config.max = 5;
      config.readonly = true;
    }

  ngOnInit() {

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.providerId = userData.ProviderID;
      this.getProviderReviews();
    }
  }

  getProviderReviews(){

    var req=new ServiceRequest();
    req.data.sn = ServiceConstant.getAllProviderReviewsSN;
    req.data.p1 = this.providerId;
    // req.data.p2 = startRowIndex;
    // req.data.p3 = recordCount;

    this.authService.consumeService(req).subscribe((res)=>{
              
      if(!res){
        return;
      }

      var serviceResponse = res || {} as ServiceResponse;

      if(serviceResponse.hasError != true)
      {
        this.clientReviews = serviceResponse.data || {} as Array<ClientReview>;
      }
    }); 
  }
}