import { ProviderInfo } from './provider-info';
import { ServiceCategory } from './ServiceCategory';
import { Office } from './Office';
import { ServicePicture } from './service-picture';

export class ProviderService{
    constructor(){}

    ServiceID: number;
    NormalPrice: number;
    DiscountedPrice: number;
    DurationMinute: number;
    Title: string;
    Description: string;
    AverageRate: number;
    RateCount: number;
    IsEnable: boolean;
    HeaderPicturePath: string;
    ProviderID: number;

    Provider: ProviderInfo;
    ServiceCategory: ServiceCategory;
    Office: Office;

    ServicePictures: Array<ServicePicture>;
}