import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Book } from 'src/app/shared/models/Book';
import { AuthService } from 'src/app/core/services/auth.service';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant, ApplicationDateFormatTypeEnum } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { BookFilterType } from 'src/app/shared/enums/book-filter-type';
import { BookSortType } from 'src/app/shared/enums/book-sort-type';

@Component({
  selector: 'app-book-list',
  templateUrl: './book-list.component.html',
  styleUrls: ['./book-list.component.css']
})
export class BookListComponent implements OnInit {

  @Input() bookstatusId: number;
  applicationDateFormatTypeEnum=ApplicationDateFormatTypeEnum;

  @Output() selectedBookChange = new EventEmitter();

  providerId:number;
  providerBooks:Array<Book>;

  constructor(private authService: AuthService) { }

  ngOnInit() {

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.providerId = userData.ProviderID;    
      this.getAllBooks();  
    }
    else{

      this.authService.redirectToLoginPage();
      return;
    }     
  }

  getAllBooks(){
    
    var req=new ServiceRequest();
    req.data.sn = ServiceConstant.getAllProviderBooksSN;
    req.data.p1 = this.providerId;
    req.data.p2 = this.bookstatusId;
    req.data.p3 = BookSortType.Normal;
    req.data.p4 = BookFilterType.ProviderBook;
    // req.data.p5 = startRowIndex;
    // req.data.p6 = recordCount;

    this.authService.consumeService(req).subscribe((res)=>{
              
      if(!res){
        return;
      }

      var serviceResponse = res || {} as ServiceResponse;

      if(serviceResponse.hasError != true)
      {
        this.providerBooks = serviceResponse.data || {} as Array<Book>;
      }
    });     
  }  

  onBookClicked(book: Book){
    
    this.selectedBookChange.emit(book);
  }
}
