import { Component, OnInit, Input } from '@angular/core';
import { Book } from 'src/app/shared/models/Book';
import { ApplicationDateFormatTypeEnum } from 'src/app/core/models/service-constant';
import { ModalService } from 'src/app/core/modal.service';
import { BookCancelComponent } from '../book-cancel/book-cancel.component';
import { BookStatus } from 'src/app/shared/enums/book-status';
import { BookDoneComponent } from '../book-done/book-done.component';
import { Router } from '@angular/router';
import { PaymentTypeEnum } from 'src/app/shared/enums/payment-type';
import { $ } from 'protractor';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { formatNumber } from '@angular/common';
import { PersianNumberService } from 'src/app/core/services/persian-number.service';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.css']
})
export class ClientInfoComponent implements OnInit {

  @Input() bookInfo: Book;

  paymentTypeEnum = PaymentTypeEnum;
  applicationDateFormatTypeEnum=ApplicationDateFormatTypeEnum;
  bookStatus= BookStatus;
  
  constructor(private modalService: ModalService
    ,private persianNumberService: PersianNumberService
    ,private router: Router) { }

  ngOnInit() {
  }

  cancelBook(){

    this.modalService.show(BookCancelComponent,this.bookInfo)
      .then(confirmed => {
        if (confirmed) {

          var message = "رزرو لغو گردید";        
  
          this.modalService.show(
            ConfirmModalComponent,
            {
              title: "", message: message ,canceltext:"باشه" ,hideConfirmButton:true
            });
  
          this.bookInfo.BookStatus = this.bookStatus.Cancel;

        } else {

        }
      });
  }

  bookDone(){

    this.modalService.show(BookDoneComponent,this.bookInfo)
    .then(confirmed => {
      if (confirmed) {

        var message = "";
        var totalPriceStr = "(" + this.persianNumberService.toPersian(formatNumber(this.bookInfo.TotalPrice,"en")) + "تومان)";
        
        //this.router.navigate(["/bookindex"]); 
        if(this.bookInfo.ActualPaymentType == this.paymentTypeEnum.Cash ){

          message = `رزرو به اتمام رسید لطفا مبلغ ${ totalPriceStr  } به صورت نقدی دریافت کنید`;
        }
        else{

          message = `رزرو به اتمام رسید مبلغ ${ totalPriceStr  } از حساب مشتری کسر می شود`;
        }

        this.modalService.show(
          ConfirmModalComponent,
          {
            title: "", message: message ,canceltext:"باشه" ,hideConfirmButton:true
          });

        this.bookInfo.BookStatus = this.bookStatus.Done;

      } else {
        console.log("cancel");
      }
    });
  }

  callClient(){
    window.location.href="tel:" + this.bookInfo.Client.MobileNumber;
  }

  getDuration(){
    return this.bookInfo.Services.map(c=>c.DurationMinute).reduce((sum, current) => sum + current);
  }

}
