export class ServiceCategory {
    constructor() { }

    ServiceCategoryID: number;
    ParentID: number;
    Name: string;
    NameTree: string;
    NameTreeMultiLanguage: string;
    Description: string;
    HasChild: boolean;
}