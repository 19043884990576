import { OfficeHeaderPicture } from './OfficeHeaderPicture';
import { MapLocation } from './MapLocation';
import { City } from './City';

export class Office{
    constructor(){}

    OfficeID: number;
    Name: string;
    PhoneNumbers: string;
    AverageRate: number;
    RateCount: number;
    Address: string;
    Description: string;
    CityID: number;
    ManagerName: string;
    ProfilePicturePath: string;

    Location: MapLocation;
    HeaderPictures: Array<OfficeHeaderPicture>;
    City: City;
}