import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProviderBookAppComponent } from './provider-book-app/provider-book-app.component';
import { BookIndexComponent } from './components/book-index/book-index.component';
import { AuthGuard } from '../core/services/auth.guard';
import { BookCancelComponent } from './components/book-cancel/book-cancel.component';
import { BookDoneComponent } from './components/book-done/book-done.component';

const routes: Routes = [
  { path: '', redirectTo: 'bookindex' ,pathMatch: 'full' },
  {
    path: "", component: ProviderBookAppComponent,
    children: [
      { path: "bookindex", component: BookIndexComponent },
      { path: "bookcancel", component: BookCancelComponent },
      { path: "bookdone", component: BookDoneComponent },
    ]
    ,canActivate: [ AuthGuard ]
    ,canActivateChild: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProviderBookRoutingModule { }
