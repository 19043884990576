import { Component, OnInit } from '@angular/core';
import { ProviderCalendarEvent } from 'src/app/shared/models/provider-calendar-event';
import { AuthService } from 'src/app/core/services/auth.service';
import { ModalService } from 'src/app/core/modal.service';
import { NgForm } from '@angular/forms';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';

@Component({
  selector: 'app-calendar-event-edit',
  templateUrl: './calendar-event-edit.component.html',
  styleUrls: ['./calendar-event-edit.component.css']
})
export class CalendarEventEditComponent implements OnInit {

  alert: AlertModel;  

  provinceId: number;

  fromTime = { hour: 0, minute: 0 };
  toTime = { hour: 0 ,minute: 0 }; 
  eventDate: number;
  description: string;

  args:ProviderCalendarEvent;

  constructor(private authService: AuthService
    ,private modalService: ModalService) { 
  }

  ngOnInit() {   

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.eventDate= this.args.EventDate;
      this.description= this.args.Description;

      this.fromTime.hour=Math.floor(this.args.StartDayMinute/60);
      this.fromTime.minute= this.args.StartDayMinute - (this.fromTime.hour * 60);
  
      this.toTime.hour=Math.floor(this.args.EndDayMinute/60);
      this.toTime.minute=this.args.EndDayMinute - (this.toTime.hour * 60);      
    }
    else{

      this.authService.redirectToLoginPage();
      return;
    }

  }

  close: (val?: any) => void;

  submitForm(form:NgForm){

    console.log


    if(form.valid != true)
      return; 

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.updateProviderCalendarEventSN;

      serviceRequest.data.p1 = this.args.ProviderCalendarEventID;
      serviceRequest.data.p2 = this.eventDate;
      serviceRequest.data.p3 = (this.fromTime.hour * 60) + this.fromTime.minute;
      serviceRequest.data.p4 = (this.toTime.hour * 60) + this.toTime.minute;
      serviceRequest.data.p5 = this.description;

      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;

        if(srvResponse.hasError != true){

          this.args.EventDate = this.eventDate;
          this.args.StartDayMinute = (this.fromTime.hour * 60) + this.fromTime.minute;
          this.args.EndDayMinute = (this.toTime.hour * 60) + this.toTime.minute;
          this.args.Description = this.description;

          this.close();
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });
  }
}
