import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToolbarService } from 'src/app/core/services/toolbar.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit,OnDestroy {

  constructor(private toolbarService: ToolbarService) { }

  ngOnInit() {

    this.toolbarService.setTitle('داشبورد');
    this.toolbarService.setBackButtonVisible(false);
    this.toolbarService.setNewButtonVisible(false);
  }

  ngOnDestroy() {

    this.toolbarService.setTitle('');
  }  

}
