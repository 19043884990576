import { Component, OnInit } from '@angular/core';
import { ProviderInfo } from 'src/app/shared/models/provider-info';
import { AuthService } from 'src/app/core/services/auth.service';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceRequestParam } from 'src/app/core/models/service-request-param';
import { NgForm } from '@angular/forms';
import { City } from 'src/app/shared/models/City';
import { Office } from 'src/app/shared/models/Office';
import { ServiceConstant } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { Router } from '@angular/router';
import { PersianNumberService } from 'src/app/core/services/persian-number.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {

  alert: AlertModel;  
  args:ProviderInfo; 

  officeList: Array<Office>;
  cityList: Array<City>;
  
  providerId:number;
  officeId: number;
  providerInfo:ProviderInfo; 
  serviceRequestParam: ServiceRequestParam = new ServiceRequestParam(); 

  constructor(private authService: AuthService
    ,private persianNumberService: PersianNumberService
    ,private router: Router,
              ) { }

  ngOnInit() {

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.providerId = userData.ProviderID;
      this.getProviderProfile();
      this.fillLookupOfficeList();            
    }
  }

  fillLookupOfficeList(){

    var officeRequest = new ServiceRequest();
    officeRequest.data.sn = ServiceConstant.getOfficesSN;

    this.authService.consumeService(officeRequest).subscribe((res)=>{
              
      if(!res){        
        return;
      }

      var serviceResponse = res || {} as ServiceResponse;
      if(serviceResponse.hasError != true)
      {
        this.officeList = serviceResponse.data || {} as Array<Office>;
      }

    });
  };

  fillLookupCityList(){

    if(!this.providerInfo)
      return;

    if( isNaN(this.providerInfo.ProvinceID) || this.providerInfo.ProvinceID <=0)
      return;

    var officeRequest = new ServiceRequest();
    officeRequest.data.sn = ServiceConstant.getCitiesByProvinceIDSN;
    officeRequest.data.p1 =this.providerInfo.ProvinceID;

    this.authService.consumeService(officeRequest).subscribe((res)=>{
              
      if(!res){        
        return;
      }

      var serviceResponse = res || {} as ServiceResponse;
      if(serviceResponse.hasError != true)
      {
        this.cityList = serviceResponse.data || {} as Array<City>;
      }

    });

  };  

  getProviderProfile(){

    this.authService.getProviderInfoAndSetLocalData(this.providerId).subscribe((res)=>{
              
      if(!res){        
        return;
      }

      this.providerInfo = res;      

      if(this.providerInfo.Offices.length > 0)
        this.officeId = this.providerInfo.Offices[0].OfficeID;
      else
        this.officeId = null;

      if(this.providerInfo.ProviderID > 0)
        this.fillLookupCityList();
    }); 
  };

  submitForm(form:NgForm) {


    this.providerInfo.SSN = this.persianNumberService.toEnglish(String(this.providerInfo.SSN));

    if(form.valid != true)
      return;   

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.updateProviderProfileInfoSN;
      serviceRequest.data.p1 = this.providerId;
      serviceRequest.data.p2 = this.providerInfo.FirstName;
      serviceRequest.data.p3 = this.providerInfo.LastName;
      serviceRequest.data.p4 = this.providerInfo.Email;
      serviceRequest.data.p5 = this.providerInfo.SSN;
      serviceRequest.data.p6 = this.providerInfo.CityID;
      serviceRequest.data.p7 = [this.officeId];
      //serviceRequest.data.p8 = isServicesMigrate;

  
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;

        if(srvResponse.hasError != true){

          //this.router.navigate(["/profileindex"], { queryParams: { mobileNumber: serviceRequest.data.p1} }); 
          
          var selectedOffie = this.officeList.find(x=>x.OfficeID == this.officeId);
          this.providerInfo.Offices = new Array<Office>();
          this.providerInfo.Offices.push(selectedOffie);
          //this.providerInfo.Offices[0].Name = this.officeList.find(x=>x.OfficeID == this.officeId).Name;
          
          this.providerInfo.CityName = this.cityList.find(x=>x.CityID == this.providerInfo.CityID).Name;

          Object.assign(this.args ,this.providerInfo);
          //this.args.ServiceID = 
          this.close(true);

        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });     
  }

  close: (val?: any) => void;
}
