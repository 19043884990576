import { Component, OnInit } from '@angular/core';
import { ServiceCategory } from 'src/app/shared/models/ServiceCategory';
import { AuthService } from 'src/app/core/services/auth.service';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant } from 'src/app/core/models/service-constant';
import { NgForm } from '@angular/forms';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { Bank } from 'src/app/shared/models/Bank';

@Component({
  selector: 'app-select-service-category',
  templateUrl: './select-service-category.component.html',
  styleUrls: ['./select-service-category.component.css']
})
export class SelectServiceCategoryComponent implements OnInit {

  alert: AlertModel;

  serviceCategoryList: Array<ServiceCategory>;

  args:ServiceCategory;

  constructor(private authService: AuthService) { }

  ngOnInit() {

    this.getSeriviceCategories(0);
  }

  getSeriviceCategories(parentId: number){ 

    var srvRequest = new ServiceRequest();
    srvRequest.data.sn = ServiceConstant.getServiceCategoriesSN;

    srvRequest.data.p1 = parentId;

    this.authService.consumeService(srvRequest).subscribe((res)=>{
              
      if(!res){        
        return;
      }

      var serviceResponse = res || {} as ServiceResponse;
      if(serviceResponse.hasError != true)
      {
        this.serviceCategoryList = serviceResponse.data || {} as Array<ServiceCategory>;
      }
    });
 }; 

 selectServieCategoryClick(serviceCategory: ServiceCategory){

   if(serviceCategory.HasChild){

    this.getSeriviceCategories(serviceCategory.ServiceCategoryID);
    return;
   }
   else{

    if(this.args == undefined){
      this.args= new ServiceCategory();
    }
    this.args.ServiceCategoryID = serviceCategory.ServiceCategoryID;
    this.args.Name = serviceCategory.Name;
    this.args.NameTree = serviceCategory.NameTree;
    this.args.HasChild = serviceCategory.HasChild;
    this.args.ParentID = serviceCategory.ParentID;
    this.args.Description = serviceCategory.Description;

    this.close(true);
   }
 }

  close: (val?: any) => void;
}
