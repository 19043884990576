import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CampaignAppComponent } from './campaign-app/campaign-app.component';
import { CampaignIndexComponent } from './components/campaign-index/campaign-index.component';
import { AuthGuard } from '../core/services/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'campaignindex' ,pathMatch: 'full' },
  {
    path: "", component: CampaignAppComponent,
    children: [
      { path: "campaignindex", component: CampaignIndexComponent },
    ]
    ,canActivate: [ AuthGuard ]
    ,canActivateChild: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CampaignRoutingModule { }
