import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProviderBookRoutingModule } from './provider-book-routing.module';
import { ProviderBookAppComponent } from './provider-book-app/provider-book-app.component';
import { BookIndexComponent } from './components/book-index/book-index.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { BookListComponent } from './components/book-list/book-list.component';
import { BookDetailComponent } from './components/book-detail/book-detail.component';
import { ClientInfoComponent } from './components/client-info/client-info.component';
import { BookServiceListComponent } from './components/book-service-list/book-service-list.component';
import { BookCancelComponent } from './components/book-cancel/book-cancel.component';
import { BookDoneComponent } from './components/book-done/book-done.component';

@NgModule({
  declarations: [
    ProviderBookAppComponent, 
    BookIndexComponent, 
    BookListComponent, BookDetailComponent, ClientInfoComponent, BookServiceListComponent, BookCancelComponent, BookDoneComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule.forRoot(),
    ProviderBookRoutingModule
  ]
})
export class ProviderBookModule { }
