import { Injectable } from "@angular/core";
//import * as jwt_decode from "jwt-decode";

import { AuthTokenType } from "./../models/auth-token-type";
import { ApiConfigService } from "./api-config.service";
import { BrowserStorageService } from "./browser-storage.service";
import { UtilsService } from "./utils.service";
import { NgModel } from "@angular/forms";
import { AuthUser } from "../models/auth-user";
import { ProviderInfo } from "src/app/shared/models/provider-info";
import { toInteger } from "@ng-bootstrap/ng-bootstrap/util/util";
import { isNullOrUndefined } from "util";

@Injectable({
  providedIn: "root",
})
export class TokenStoreService {
  private rememberMeToken = "rememberMe_token";
  private userDataToken = "userData_token";

  constructor(
    private browserStorageService: BrowserStorageService,
    private utilsService: UtilsService,
    private apiConfigService: ApiConfigService
  ) {}

  getRawAuthToken(tokenType: AuthTokenType): string {
    if (this.rememberMe()) {
      return this.browserStorageService.getLocal(AuthTokenType[tokenType]);
    } else {
      return this.browserStorageService.getSession(AuthTokenType[tokenType]);
    }
  }

  getDecodedAccessToken(): any {
    //return jwt_decode(this.getRawAuthToken(AuthTokenType.AccessToken));

    var currentUser: AuthUser;
    // currentUser.displayName = "iraj chardoli";
    // currentUser.userId = "100";
    // currentUser.userName = "09226546483";

    return currentUser; //ToDo: Ir.Ch => implement this
  }

  getAuthUserDisplayName(): string {
    return this.getDecodedAccessToken().DisplayName;
  }

  getAccessTokenExpirationDateUtc(): Date | null {
    const decoded = this.getDecodedAccessToken();
    if (decoded.exp === undefined) {
      return null;
    }
    const date = new Date(0); // The 0 sets the date to the epoch
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isAccessTokenTokenExpired(): boolean {
    const expirationDateUtc = this.getAccessTokenExpirationDateUtc();
    if (!expirationDateUtc) {
      return true;
    }
    return !(expirationDateUtc.valueOf() > new Date().valueOf());
  }

  deleteAuthTokens() {
    if (this.rememberMe()) {
      this.browserStorageService.removeLocal(
        AuthTokenType[AuthTokenType.AccessToken]
      );
      this.browserStorageService.removeLocal(
        AuthTokenType[AuthTokenType.RefreshToken]
      );
    } else {
      this.browserStorageService.removeSession(
        AuthTokenType[AuthTokenType.AccessToken]
      );
      this.browserStorageService.removeSession(
        AuthTokenType[AuthTokenType.RefreshToken]
      );
    }
    this.browserStorageService.removeLocal(this.rememberMeToken);
  }

  setToken(tokenType: AuthTokenType, tokenValue: string): void {
    if (this.utilsService.isEmptyString(tokenValue)) {
      console.error(`${AuthTokenType[tokenType]} is null or empty.`);
    }

    if (
      tokenType === AuthTokenType.AccessToken &&
      this.utilsService.isEmptyString(tokenValue)
    ) {
      throw new Error("AccessToken can't be null or empty.");
    }

    if (this.rememberMe()) {
      this.browserStorageService.setLocal(AuthTokenType[tokenType], tokenValue);
    } else {
      this.browserStorageService.setSession(
        AuthTokenType[tokenType],
        tokenValue
      );
    }
  }

  getDecodedTokenRoles(): string[] | null {
    const decodedToken = this.getDecodedAccessToken();
    const roles =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    if (!roles) {
      return null;
    }

    if (Array.isArray(roles)) {
      return roles.map((role) => role.toLowerCase());
    } else {
      return [roles.toLowerCase()];
    }
  }

  storeLoginSession(response: any): void {
    this.setToken(
      AuthTokenType.AccessToken,
      response[this.apiConfigService.configuration.accessTokenObjectKey]
    );
    this.setToken(
      AuthTokenType.RefreshToken,
      response[this.apiConfigService.configuration.refreshTokenObjectKey]
    );
  }

  setUserData(userData: ProviderInfo): void {
    var providerId = userData.ProviderID;

    if (userData.ProviderID <= 0) {
      console.error(`PID is not valid.`);
      throw new Error("PID is not valid.");
    }

    var dataKey = btoa(this.userDataToken);
    userData.OtherData = btoa(userData.ProviderID.toString());
    userData.ProviderID = -1;
    // var dataValue = btoa(JSON.stringify(userData));

    // if (this.rememberMe()) {
    //   this.browserStorageService.setLocal(dataKey, dataValue);
    // } else {
    //   this.browserStorageService.setSession(dataKey, dataValue);
    // }

    if (this.rememberMe()) {
      this.browserStorageService.setLocal(dataKey, JSON.stringify(userData));
    } else {
      this.browserStorageService.setSession(dataKey, JSON.stringify(userData));
    }

    userData.ProviderID = providerId;
  }

  hasStoredValidUserData(): boolean {
    // var jsonUserData = "";
    // if (this.rememberMe()) {
    //   jsonUserData = this.browserStorageService.getLocal(this.userDataToken);
    // } else {
    //   jsonUserData =  this.browserStorageService.getSession(this.userDataToken);
    // }

    // var userData = JSON.parse(jsonUserData) || {} as ProviderInfo;

    var userData = this.getUserData();

    if (isNullOrUndefined(userData)) return false;

    if (userData.ProviderID > 0) return true;
    else return false;
  }

  getUserData(): ProviderInfo {
    var dataKey = btoa(this.userDataToken);

    //var jsonUserData = "";

    //====================
    // var encryptedData = "";
    // if (this.rememberMe()) {
    //   encryptedData = this.browserStorageService.getLocal(dataKey);
    // } else {
    //   encryptedData =  this.browserStorageService.getSession(dataKey);
    // }

    // var jsonUserData= atob(encryptedData);
    //====================

    // if (this.rememberMe()) {
    //   jsonUserData = this.browserStorageService.getLocal(dataKey);
    // } else {
    //   jsonUserData =  this.browserStorageService.getSession(dataKey);
    // }

    //=========================

    try {
      var jsonUserData = this.browserStorageService.getLocal(dataKey);

      var userData = JSON.parse(jsonUserData) || ({} as ProviderInfo);

      var userproviderId = atob(userData.OtherData);
      userData.ProviderID = userproviderId; // userproviderId;

      return userData;
    } catch (e) {
      console.log("error999");
    }
  }

  deleteUserDataTokens() {
    this.browserStorageService.removeLocal(this.userDataToken);
    this.browserStorageService.removeSession(this.userDataToken);

    var dataKey = btoa(this.userDataToken);

    this.browserStorageService.removeLocal(dataKey);
    this.browserStorageService.removeSession(dataKey);
    this.browserStorageService.removeLocal(this.rememberMeToken);
  }

  rememberMe(): boolean {
    return this.browserStorageService.getLocal(this.rememberMeToken) === true;
  }

  setRememberMe(value: boolean): void {
    this.browserStorageService.setLocal(this.rememberMeToken, value);
  }

  hasStoredAccessAndRefreshTokens(): boolean {
    const accessToken = this.getRawAuthToken(AuthTokenType.AccessToken);
    const refreshToken = this.getRawAuthToken(AuthTokenType.RefreshToken);
    return (
      !this.utilsService.isEmptyString(accessToken) &&
      !this.utilsService.isEmptyString(refreshToken)
    );
  }
}
