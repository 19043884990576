import { Component, OnInit } from '@angular/core';
import { ProviderCalendarEvent } from 'src/app/shared/models/provider-calendar-event';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { ToolbarService } from 'src/app/core/services/toolbar.service';
import { ModalService } from 'src/app/core/modal.service';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant, ApplicationDateFormatTypeEnum } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { CalendarEventEditComponent } from '../calendar-event-edit/calendar-event-edit.component';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { CalendarEventCreateComponent } from '../calendar-event-create/calendar-event-create.component';

@Component({
  selector: 'app-calendar-event-index',
  templateUrl: './calendar-event-index.component.html',
  styleUrls: ['./calendar-event-index.component.css']
})
export class CalendarEventIndexComponent implements OnInit {

  alert: AlertModel;  
  applicationDateFormatTypeEnum=ApplicationDateFormatTypeEnum;

  providerId:number;
  providerCalendarEvents:Array<ProviderCalendarEvent>;

  addButtonClickSubscription: Subscription;
  backButtonClickSubscription: Subscription;
  menuFilterClickSubscription: Subscription;

  constructor(private authService: AuthService 
             ,private router: Router
             ,private toolbarService: ToolbarService
             ,private modalService: ModalService ) {}

  ngOnInit() {

    this.toolbarService.setTitle('تقویم کاری');
    this.toolbarService.setBackButtonVisible(true);
    this.toolbarService.setNewButtonVisible(true);
    // this.toolbarService.setMenuFilterVisibleIND(true);

    this.addButtonClickSubscription = this.toolbarService.newItemClick$.subscribe(event=>{
      this.addButtonClicked(event);
    });

    this.backButtonClickSubscription = this.toolbarService.backButtonClick$.subscribe(event=>{
      this.backButtonClicked(event);
    });    

    this.menuFilterClickSubscription = this.toolbarService.menuFilterClick$.subscribe(event=>{
      this.menuFilterClicked(event);
    });         

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.providerId = userData.ProviderID;    
      this.getAllProviderCalendarEvents();  
    }
    else{

      this.authService.redirectToLoginPage();
      return;
    }   
  }

  ngOnDestroy() {

    this.toolbarService.setTitle('');    
    this.toolbarService.setBackButtonVisible(false);
    this.toolbarService.setNewButtonVisible(false);
    this.toolbarService.setMenuFilterVisibleIND(false);

    this.addButtonClickSubscription.unsubscribe();
    this.backButtonClickSubscription.unsubscribe();
    this.menuFilterClickSubscription.unsubscribe();
  }

  backButtonClicked(event: Event){
    this.router.navigate(['/dashboard']);
  }

  menuFilterClicked(event: Event){
    console.log("filter clicked");
  }

  getAllProviderCalendarEvents(){
    
    var req=new ServiceRequest();
    req.data.sn = ServiceConstant.getAllProviderCalendarEventsSN;
    req.data.p1 = this.providerId;
     req.data.p2 = 1;//sortType;
     req.data.p3 = 1; //filterType == All;
     //req.data.p4 = //startRowIndex;
     //req.data.p5 = //recordCount;

    this.authService.consumeService(req).subscribe((res)=>{
              
      if(!res){
        return;
      }

      var serviceResponse = res || {} as ServiceResponse;

      if(serviceResponse.hasError != true)
      {
        this.providerCalendarEvents = serviceResponse.data || {} as Array<ProviderCalendarEvent>;
      }
    });     
  }

  editProviderCalendarEvent(providerCalendarEventId: number){

    this.modalService.show(
      CalendarEventEditComponent,this.providerCalendarEvents.find(x => x.ProviderCalendarEventID == providerCalendarEventId))
      .then(confirmed => {
        if (confirmed) {

          //console.log("confirmed");
        } else {

          //this.confirmResult = "Canceled!";    
          //console.log("cancel");      
        }
      });
  }

  confirmDeleteRecord(providerCalendarEventId: number){

    this.modalService.show(
      ConfirmModalComponent,
      {
        title: "سوال", message: "آیا از حذف رکورد اطمینان دارید؟"
      })
      .then(confirmed => {
        if (confirmed) {

          this.deleteProviderCalendarEvent(providerCalendarEventId);
        } else {

          //this.confirmResult = "Canceled!";          
        }
      });
  }

  deleteProviderCalendarEvent(providerCalendarEventId: number){

    if(isNaN(this.providerId) || this.providerId <=0){
      this.authService.redirectToLoginPage();
      return;
    }   

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.deleteProviderCalendarEventSN;
      serviceRequest.data.p1 = providerCalendarEventId;

  
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;
        
        if(srvResponse.hasError != true){

          const itemIndex = this.providerCalendarEvents.findIndex(obj=> obj["ProviderCalendarEventID"] == providerCalendarEventId);
          this.providerCalendarEvents.splice(itemIndex, 1);
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });      

  }

  addButtonClicked(event: Event){

    this.modalService.show(CalendarEventCreateComponent)
      .then(confirmed => {
        if (confirmed) {

          // console.log("confirmed");
          // console.log(newServiceProvider);

          this.getAllProviderCalendarEvents();

        } else {   
        }
      });
  }
}
