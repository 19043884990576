import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar-event-app',
  templateUrl: './calendar-event-app.component.html',
  styleUrls: ['./calendar-event-app.component.css']
})
export class CalendarEventAppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
