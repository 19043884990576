import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { ProviderInfo } from 'src/app/shared/models/provider-info';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.css']
})
export class ViewProfileComponent implements OnInit {

  providerId:number;
  providerInfo:ProviderInfo;

  constructor(private authService: AuthService) { }

  ngOnInit() {

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.providerId = userData.ProviderID;
      this.getProviderProfile();
    }
  }

  getProviderProfile(){

    this.authService.getProviderInfoAndSetLocalData(this.providerId).subscribe((res)=>{
              
      if(!res){
        //this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید.10011"};
        return;
      }

      this.providerInfo = res;
    }); 

  };
}