import { Component, OnInit, Input, ViewChild, Inject, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ProviderService } from 'src/app/shared/models/ProviderService';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant, ApplicationDateFormatTypeEnum } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { ClientReview } from 'src/app/shared/models/ClientReview';

import { NgbCarousel, NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { ServicePicture } from 'src/app/shared/models/service-picture';
import { APP_CONFIG, IAppConfig } from 'src/app/core/services/app.config';
import { ModalService } from 'src/app/core/modal.service';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { ToolbarService } from 'src/app/core/services/toolbar.service';
import { Subscription } from 'rxjs';
import { ServiceEditComponent } from '../service-edit/service-edit.component';


@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.css']
})
export class ServiceDetailComponent implements OnInit ,OnDestroy {

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  //=========================
  //=========================

  alert: AlertModel;  
  fileToUpload: File = null;

  servicePictures: Array<ServicePicture>;
  imagePrefix: string;
  serviceId:number;

providerService: ProviderService;
clientReviews: Array<ClientReview>;

editToolbarButtonSubscription: Subscription;
backButtonClickSubscription: Subscription;

applicationDateFormatTypeEnum=ApplicationDateFormatTypeEnum;

  constructor(private authService: AuthService,
              private route: ActivatedRoute,     
              private modalService: ModalService ,
              private toolbarService: ToolbarService,
              private config: NgbRatingConfig,
  @Inject(APP_CONFIG) private appConfig: IAppConfig,
              private router: Router) { 

                this.imagePrefix = `${this.appConfig.imageEndpoint}/`;
                this.setDefaultServicePicture();

                config.max = 5;
                config.readonly = true;
              }

setDefaultServicePicture(){

  if(this.servicePictures !=null && this.servicePictures.length > 0)
    return;

  var pic = new ServicePicture();
  pic.ServicePictureID = 0;
  pic.ServicePicturePath = "/assets/images/no_picture.jpg";

  this.servicePictures = new Array<ServicePicture>();
  this.servicePictures.push(pic);
}

  ngOnInit() {

    this.setupToolbar();

    this.route.params.subscribe(params => {
      
      this.serviceId = params.id as number;
      if(isNaN(this.serviceId) || this.serviceId <=0){
        this.router.navigate(["/providerserviceindex"]);
        return;
      };

      this.getProviderServiceInfo();
      this.getAllServiceReviews();

    });

  }

  ngOnDestroy() {

    this.editToolbarButtonSubscription.unsubscribe();
    this.backButtonClickSubscription.unsubscribe();

    this.toolbarService.setBackButtonVisible(false);
    this.toolbarService.setEditButtonVisible(false);
  }

  setupToolbar(){

    this.toolbarService.setTitle('');
    this.toolbarService.setBackButtonVisible(true);
    this.toolbarService.setEditButtonVisible(true);
    this.toolbarService.setNewButtonVisible(false);
    this.toolbarService.setMenuFilterVisibleIND(false);
    this.toolbarService.setMenuSortVisibleIND(false);

    this.editToolbarButtonSubscription = this.toolbarService.editButtonClick$.subscribe(event=>{
      this.editButtonClicked(event);
    });

    this.backButtonClickSubscription = this.toolbarService.backButtonClick$.subscribe(event=>{
      this.backButtonClicked(event);
    }); 
  }


  getProviderServiceInfo(){

    var serviceRequest= new ServiceRequest();    
    serviceRequest.data.sn = ServiceConstant.getServiceByServiceIDSN;
    serviceRequest.data.p1 = this.serviceId;

    this.authService.consumeService(serviceRequest).subscribe((response)=>{

      if(!response){        
        return;
      }

      var serviceResponse = response || {} as ServiceResponse;
      if(serviceResponse.hasError != true)
      {
        this.providerService = serviceResponse.data || {} as ProviderService;

        if(this.providerService.ServicePictures.length> 0)
        {
          this.servicePictures = this.providerService.ServicePictures;
        }
      }

    }); 
  }

  getAllServiceReviews(){

    var serviceRequest= new ServiceRequest();    
    serviceRequest.data.sn = ServiceConstant.getAllServiceReviews;
    serviceRequest.data.p1 = this.serviceId;
    // serviceRequest.data.p3 = startRowIndex;
    // serviceRequest.data.p4 = recordCount;

    this.authService.consumeService(serviceRequest).subscribe((response)=>{

      if(!response){        
        return;
      }

      var serviceResponse = response || {} as ServiceResponse;
      if(serviceResponse.hasError != true)
      {
        this.clientReviews = serviceResponse.data || {} as Array<ClientReview>;
      }

    }); 

  }  

  confirmRemovePicture(servicePicture: ServicePicture){

    if(servicePicture.ServicePictureID <= 0)
      return;

    this.modalService.show(
      ConfirmModalComponent,
      {
        title: "سوال", message: "آیا از حذف تصویر اطمینان دارید ؟"
      })
      .then(confirmed => {
        if (confirmed) {

          this.removePicture(servicePicture);
        } else {

          //this.confirmResult = "Canceled!";          
        }
      });


  }  

  removePicture(servicePicture: ServicePicture){

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.deleteServicePictureSN;
      serviceRequest.data.p1 = servicePicture.ServicePictureID;
  
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;
        
        if(srvResponse.hasError != true){

          const itemIndex = this.servicePictures.findIndex(obj=> obj["ServicePictureID"] == servicePicture.ServicePictureID);
          if(itemIndex >= 0){
            this.servicePictures.splice(itemIndex, 1);
            this.setDefaultServicePicture();
          }
                  
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });   
  }

  onFileSelected(event){
    
    // if( (<File>event.target.files[0]).size > 20000){

    //   this.alert = {type: "danger" ,message: "سایز عکس انتخاب شده بزرگ می باشد."};
    //   return;
    // }

    var fileType = (<File>event.target.files[0]).type;
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    if (!validImageTypes.includes(fileType)) {
        this.alert = {type: "danger" ,message: " عکس انتخاب شده معتبر نمی باشد."};
        return;
    }

    this.fileToUpload = <File>event.target.files[0];    

    var reader = new FileReader();
    
    reader.onload = function (e) {
    };
    reader.readAsDataURL(<File>event.target.files[0]);
    
    this.uploadServicePicture();
  }

  uploadServicePicture(){

    var srvRequest= new ServiceRequest();
    srvRequest.data.sn = ServiceConstant.updateServiceHeaderPictureSN;
    srvRequest.data.p1 = this.providerService.ServiceID;

    this.authService.uploadImageService(srvRequest,this.fileToUpload).subscribe((response)=>{

      if (!response) {

        this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

        return null;
      }

      var srvResponse = response || {} as ServiceResponse;

      if(srvResponse.hasError != true){
        
        var newServicePicture = srvResponse.data || {} as ServicePicture;

         if(newServicePicture.ServicePictureID > 0){
           
          

          const itemIndex = this.servicePictures.findIndex(obj=> obj["ServicePictureID"] == 0);
          if(itemIndex >=0){
            this.servicePictures.splice(itemIndex, 1);
          }

          newServicePicture.ServicePicturePath =newServicePicture.ServicePicturePath  + '?' + (new Date()).getTime();;
          this.servicePictures.push(newServicePicture);
         }

        this.alert = {type: "success" ,message: "اطلاعات با موفقت ثبت گردید"};  
      }
      else if(srvResponse.error.message)
        this.alert = {type: "danger" ,message: srvResponse.error.message};   
      else
        this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
    });
  }

  backButtonClicked(event: Event){

    this.router.navigate(["/providerserviceindex"]);
  }

  editButtonClicked(event: Event){

    this.modalService.show(ServiceEditComponent ,this.providerService)
      .then(confirmed => {
        if (confirmed) {

          // console.log("confirmed");
          // console.log(newServiceProvider);

          //this.getAllProviderServices();

        } else {

          //this.confirmResult = "Canceled!";    
          console.log("cancel");      
        }
      });
  }
}