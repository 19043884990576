import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant } from 'src/app/core/models/service-constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { ProviderService } from 'src/app/shared/models/ProviderService';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/core/modal.service';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { ToolbarService } from 'src/app/core/services/toolbar.service';
import { Subscription } from 'rxjs';
import { ServiceCreateComponent } from '../service-create/service-create.component';

@Component({
  selector: 'app-service-index',
  templateUrl: './service-index.component.html',
  styleUrls: ['./service-index.component.css']
})
export class ServiceIndexComponent implements OnInit ,OnDestroy {

  alert: AlertModel;  

  providerId:number;
  providerServices:Array<ProviderService>;

  addNewServiceProviderSubscription: Subscription;
  backButtonClickSubscription: Subscription;
  menuFilterClickSubscription: Subscription;
  menuSortClickSubscription: Subscription;

  constructor(private authService: AuthService 
             ,private toolbarService: ToolbarService
             ,private router: Router
             ,private modalService: ModalService) { }

  ngOnInit() {

    this.toolbarService.setTitle('سرویس ها');
    this.toolbarService.setBackButtonVisible(true);
    this.toolbarService.setNewButtonVisible(true);
    this.toolbarService.setMenuFilterVisibleIND(false);
    this.toolbarService.setMenuSortVisibleIND(false);

    this.addNewServiceProviderSubscription = this.toolbarService.newItemClick$.subscribe(event=>{
      this.addnewServiceProviderButtonClicked(event);
    });

    this.backButtonClickSubscription = this.toolbarService.backButtonClick$.subscribe(event=>{
      this.backButtonClicked(event);
    });    

    this.menuFilterClickSubscription = this.toolbarService.menuFilterClick$.subscribe(event=>{
      this.menuFilterClicked(event);
    });     
    
    this.menuSortClickSubscription = this.toolbarService.menuSortClick$.subscribe(event=>{
      this.menuSortClicked(event);
    });       

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.providerId = userData.ProviderID;
      this.getAllProviderServices();
    }    
  }

  ngOnDestroy() {

    this.toolbarService.setTitle('');    
    this.toolbarService.setBackButtonVisible(false);
    this.toolbarService.setNewButtonVisible(false);
    this.toolbarService.setMenuFilterVisibleIND(false);
    this.toolbarService.setMenuSortVisibleIND(false);

    this.addNewServiceProviderSubscription.unsubscribe();
    this.backButtonClickSubscription.unsubscribe();
    this.menuFilterClickSubscription.unsubscribe();
    this.menuSortClickSubscription.unsubscribe();
  }

  addnewServiceProviderButtonClicked(event: Event){

    var newServiceProvider=new ProviderService();
    this.modalService.show(ServiceCreateComponent ,newServiceProvider)
      .then(confirmed => {
        if (confirmed) {

          // console.log("confirmed");
          // console.log(newServiceProvider);

          this.getAllProviderServices();

        } else {

          //this.confirmResult = "Canceled!";    
          console.log("cancel");      
        }
      });
  }

  backButtonClicked(event: Event){
    this.router.navigate(['/dashboard']);
  }

  menuFilterClicked(event: Event){
    console.log("filter clicked");
  }

  menuSortClicked(event: Event){
    console.log("sort clicked");
  }

  getAllProviderServices(){
    
    var req=new ServiceRequest();
    req.data.sn = ServiceConstant.getAllProviderServicesSN;
    req.data.p1 = this.providerId;
     req.data.p2 = 1; // sortType == Normal;
    // req.data.p3 = filterType;
    // req.data.p4 = startRowIndex;
    // req.data.p5 = recordCount;

    this.authService.consumeService(req).subscribe((res)=>{
              
      if(!res){
        return;
      }

      var serviceResponse = res || {} as ServiceResponse;

      if(serviceResponse.hasError != true)
      {
        this.providerServices = serviceResponse.data || {} as Array<ProviderService>;
      }
    });     
  }

  redirectToDetailView(serviceID: number){
    this.router.navigate(['\providerservicedetail' ,{id: serviceID}]);
  }

  redirectToEditView(serviceID: number){
    this.router.navigate(['\providerserviceedit' ,{id: serviceID}]);
  }  

  confirmDeleteRecord(serviceID: number){

    this.modalService.show(
      ConfirmModalComponent,
      {
        title: "سوال", 
        message: "آیا از حذف اطلاعات اطمینان دارید؟",        
      })
      .then(confirmed => {
        if (confirmed) {

          this.deleteProviderService(serviceID);
        } else {

          //this.confirmResult = "Canceled!";          
        }
      });


  }  

  deleteProviderService(serviceID: number){

    if(isNaN(this.providerId) || this.providerId <=0){
      this.authService.redirectToLoginPage();
      return;
    }    

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.deleteServiceSN;
      serviceRequest.data.p1 = serviceID;

  
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;
        
        if(srvResponse.hasError != true){

          const itemIndex = this.providerServices.findIndex(obj=> obj["ServiceID"] == serviceID);
          this.providerServices.splice(itemIndex, 1);
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });      

  }

}
