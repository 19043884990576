import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant } from 'src/app/core/models/service-constant';
import { Office } from 'src/app/shared/models/Office';
import { MapLocation } from 'src/app/shared/models/MapLocation';
import { AuthService } from 'src/app/core/services/auth.service';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { City } from 'src/app/shared/models/City';
import { ServiceRequestParam } from 'src/app/core/models/service-request-param';
import { ModalService } from 'src/app/core/modal.service';
import { OfficeLocationComponent } from '../office-location/office-location.component';

@Component({
  selector: 'app-office-edit',
  templateUrl: './office-edit.component.html',
  styleUrls: ['./office-edit.component.css']
})
export class OfficeEditComponent implements OnInit {

  alert: AlertModel;  

  provinceName: any;
  provinceId: number;
  cityList: Array<City>;

  officeInfo:Office; 
  args:Office;

  constructor(private authService: AuthService
    ,private modalService: ModalService) { 
  }

  ngOnInit() {   

    // if(this.args){

    //   this.modelItem = new ServiceRequest();
    //   this.modelItem.data.sn = ServiceConstant.updateOfficeSN;
    //   this.modelItem.data.p1 = this.args.OfficeID;
    //   this.modelItem.data.p2 = this.args.CityID;
    //   this.modelItem.data.p3 = this.args.Name;
    //   this.modelItem.data.p4 = this.args.PhoneNumbers;
    //   this.modelItem.data.p5 = this.args.Location;
    //   // this.modelItem.data.p5.Lat=0;
    //   // this.modelItem.data.p5.Lng=0;
    //   this.modelItem.data.p6 = this.args.Address;
    //   this.modelItem.data.p7 = this.args.Description;
    //   this.modelItem.data.p8 = this.args.ManagerName;
    // }

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.provinceName =userData.ProvinceName;
      this.provinceId = userData.ProvinceID;

      this.getOfficeInfo();
      this.fillLookupCityList();
    }
    else{

      this.authService.redirectToLoginPage();
      return;
    }

  }
  
  getOfficeInfo(){

    var serviceRequest= new ServiceRequest();    
    serviceRequest.data.sn = ServiceConstant.getOfficeByOfficeIDSN;
    serviceRequest.data.p1 = this.args.OfficeID;

    this.authService.consumeService(serviceRequest).subscribe((response)=>{

      if(!response){        
        return;
      }

      var serviceResponse = response || {} as ServiceResponse;
      if(serviceResponse.hasError != true)
      {
        this.officeInfo = serviceResponse.data || {} as Office;
      }

    }); 

  } 

  fillLookupCityList(){ 

    if( isNaN(this.provinceId) || this.provinceId <=0)
      return;

    var officeRequest = new ServiceRequest();
    officeRequest.data.sn = ServiceConstant.getCitiesByProvinceIDSN;
    officeRequest.data.p1 =this.provinceId;

    this.authService.consumeService(officeRequest).subscribe((res)=>{
              
      if(!res){        
        return;
      }

      var serviceResponse = res || {} as ServiceResponse;
      if(serviceResponse.hasError != true)
      {
        this.cityList = serviceResponse.data || {} as Array<City>;
      }

    });

  }; 

  close: (val?: any) => void;

  submitForm(form:NgForm){

    if(form.valid != true)
      return; 

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.updateOfficeSN;

      serviceRequest.data.p1 = this.officeInfo.OfficeID;
      serviceRequest.data.p2 = this.officeInfo.CityID;
      serviceRequest.data.p3 = this.officeInfo.Name;
      serviceRequest.data.p4 = this.officeInfo.PhoneNumbers;
      serviceRequest.data.p5 = this.officeInfo.Location;
      serviceRequest.data.p6 = this.officeInfo.Address;
      serviceRequest.data.p7 = this.officeInfo.Description;
      serviceRequest.data.p8 = this.officeInfo.ManagerName;

      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;

        if(srvResponse.hasError != true){

          this.args.CityID = this.officeInfo.CityID;
          this.args.Name = this.officeInfo.Name;
          this.args.PhoneNumbers = this.officeInfo.PhoneNumbers;
          this.args.Location = this.officeInfo.Location;
          this.args.Address = this.officeInfo.Address;
          this.args.Description = this.officeInfo.Description;
          this.args.ManagerName = this.officeInfo.ManagerName;

          this.close();
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });
  }

  selectLocation(){

    this.modalService.show(
      OfficeLocationComponent,this.officeInfo.Location)
      .then(confirmed => {
        if (confirmed) {

          // console.log("confirmed");
          // console.log(this.location);
        } else {

          //this.confirmResult = "Canceled!";    
          //console.log("cancel");      
        }
      });
  }  
}
