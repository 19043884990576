import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-provider-calendar-app',
  templateUrl: './provider-calendar-app.component.html',
  styleUrls: ['./provider-calendar-app.component.css']
})
export class ProviderCalendarAppComponent implements OnInit {

  constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) { 

    iconRegistry.addSvgIcon(
      "discount",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/discount.svg")
    );
  }

  ngOnInit() {
  }

}
