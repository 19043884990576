import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardAppComponent } from './dashboard-app/dashboard-app.component';
import { HomeComponent } from './components/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';

@NgModule({
  declarations: [
    DashboardAppComponent, 
    HomeComponent, 
    AboutUsComponent, TermsAndConditionsComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule.forRoot(),
    DashboardRoutingModule
  ]
})
export class DashboardModule { }
