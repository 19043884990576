import { Component, OnInit, OnDestroy } from '@angular/core';
import { BookStatus } from 'src/app/shared/enums/book-status';
import { Book } from 'src/app/shared/models/Book';
import { ToolbarService } from 'src/app/core/services/toolbar.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-book-index',
  templateUrl: './book-index.component.html',
  styleUrls: ['./book-index.component.css']
})
export class BookIndexComponent implements OnInit, OnDestroy {

  bookStatusEnum = BookStatus;
  detailMode:boolean = false;
  selectedBook: Book;

  backButtonClickSubscription: Subscription;
  menuFilterClickSubscription: Subscription;
  menuSortClickSubscription: Subscription;

  constructor(private toolbarService: ToolbarService
    ,private router: Router) {}

  ngOnInit() {

    this.toolbarService.setTitle('رزروها');
    this.toolbarService.setBackButtonVisible(true);
    this.toolbarService.setMenuFilterVisibleIND(false); //Badan bayad true shavad
    this.toolbarService.setMenuSortVisibleIND(false);    //Badan bayad true shavad

    this.backButtonClickSubscription = this.toolbarService.backButtonClick$.subscribe(event=>{
      this.backButtonClicked(event);
    });    

    this.menuFilterClickSubscription = this.toolbarService.menuFilterClick$.subscribe(event=>{
      this.menuFilterClicked(event);
    });     
    
    this.menuSortClickSubscription = this.toolbarService.menuSortClick$.subscribe(event=>{
      this.menuSortClicked(event);
    });      
  }

  ngOnDestroy() {    

    this.toolbarService.setTitle('');    
    this.toolbarService.setBackButtonVisible(false);
    this.toolbarService.setNewButtonVisible(false);
    this.toolbarService.setMenuFilterVisibleIND(false);
    this.toolbarService.setMenuSortVisibleIND(false);

    this.backButtonClickSubscription.unsubscribe();
    this.menuFilterClickSubscription.unsubscribe();
    this.menuSortClickSubscription.unsubscribe();    
  }

  selectChange(book:Book){
        
    this.selectedBook = book;
    this.detailMode = true;    
  }

  backButtonClicked(event: Event){

    if(this.detailMode)
      this.detailMode = !this.detailMode;
    else
      this.router.navigate(['/dashboard']);
  }

  menuFilterClicked(event: Event){
    console.log("filter clicked");
  }

  menuSortClicked(event: Event){
    console.log("sort clicked");
  }

}