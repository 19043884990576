export class ServiceRequestParam{
    constructor(){}
    
    sn: any;
    p1: any;
    p2?: any;
    p3?: any;
    p4?: any;
    p5?: any;
    p6?: any;
    p7?: any;
    p8?: any;
    p9?: any;
    p10?: any;
    file?:any;
}