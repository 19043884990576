import { Component, OnInit, Input } from '@angular/core';
import { BookService } from 'src/app/shared/models/book-service';

@Component({
  selector: 'app-book-service-list',
  templateUrl: './book-service-list.component.html',
  styleUrls: ['./book-service-list.component.css']
})
export class BookServiceListComponent implements OnInit {

  constructor() { }

  @Input() bookServices: Array<BookService>;

  ngOnInit() {
  }

}
