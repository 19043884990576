import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProviderCalendarAppComponent } from './provider-calendar-app/provider-calendar-app.component';
import { CalendarIndexComponent } from './components/calendar-index/calendar-index.component';
import { CalendarCreateComponent } from './components/calendar-create/calendar-create.component';
import { CalendarEditComponent } from './components/calendar-edit/calendar-edit.component';
import { AuthGuard } from '../core/services/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'calendarindex' ,pathMatch: 'full' },
  {
    path: "", component: ProviderCalendarAppComponent,
    children: [
      { path: "calendarindex", component: CalendarIndexComponent },      
      { path: "calendarcreate", component: CalendarCreateComponent },
      { path: "calendaredit", component: CalendarEditComponent },
    ]
    ,canActivate: [ AuthGuard ]
    ,canActivateChild: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProviderCalendarRoutingModule { }
