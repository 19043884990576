import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FinancialRoutingModule } from './financial-routing.module';
import { FinancialAppComponent } from './financial-app/financial-app.component';
import { FinancialIndexComponent } from './components/financial-index/financial-index.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    FinancialAppComponent, 
    FinancialIndexComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule.forRoot(),
    FinancialRoutingModule
  ]
})
export class FinancialModule { }
