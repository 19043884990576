import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProviderServiceRoutingModule } from './provider-service-routing.module';
import { ProviderServiceAppComponent } from './provider-service-app/provider-service-app.component';
import { ServiceIndexComponent } from './components/service-index/service-index.component';
import { ServiceDetailComponent } from './components/service-detail/service-detail.component';
import { ServiceEditComponent } from './components/service-edit/service-edit.component';
import { ServiceCreateComponent } from './components/service-create/service-create.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { SelectServiceCategoryComponent } from './components/select-service-category/select-service-category.component';

@NgModule({
  declarations: [ProviderServiceAppComponent, ServiceIndexComponent, ServiceDetailComponent, ServiceEditComponent, ServiceCreateComponent, SelectServiceCategoryComponent],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule.forRoot(),
    ProviderServiceRoutingModule
  ]
})
export class ProviderServiceModule { }
