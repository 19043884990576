import { Component, OnInit } from '@angular/core';
import { ProviderService } from 'src/app/shared/models/ProviderService';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { ServiceCategory } from 'src/app/shared/models/ServiceCategory';
import { ModalService } from 'src/app/core/modal.service';
import { SelectServiceCategoryComponent } from '../select-service-category/select-service-category.component';
import { PersianNumberService } from 'src/app/core/services/persian-number.service';

@Component({
  selector: 'app-service-create',
  templateUrl: './service-create.component.html',
  styleUrls: ['./service-create.component.css']
})
export class ServiceCreateComponent implements OnInit {
  
  alert: AlertModel;  
  args:ProviderService;

  providerId:number;
  officeId:number;
  serviceCategoryId: number;
  serviceInfo:ProviderService; 

  serviceCategoriyList: Array<ServiceCategory>;
  selectServiceCategory: ServiceCategory;

  constructor(private authService: AuthService
             ,private persianNumberService: PersianNumberService
             ,private modalService: ModalService
             ,private router: Router) { 
    this.serviceInfo = new ProviderService();
    this.serviceInfo.IsEnable = true;

    this.selectServiceCategory = new ServiceCategory();
  }

  ngOnInit() {

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.providerId = userData.ProviderID;

      if(userData.Offices.length > 0)
        this.officeId = userData.Offices[0].OfficeID;
      else
      {
        this.alert = {type: "danger" ,message: "لطفا ابتدا آرایشگاه را از قسمت پروفایل کاربر انتخاب نمائید."};
      }   
    }
    else{

      this.authService.redirectToLoginPage();
      return;
    }
  }

  // fillLookupServiceCategoryList(){

  //   var serviceRequest = new ServiceRequest();
  //   serviceRequest.data.sn = ServiceConstant.getCitiesByProvinceIDSN;
  //   officeRequest.data.p1 =this.providerInfo.ProvinceID;

  //   this.authService.consumeService(officeRequest).subscribe((res)=>{
              
  //     if(!res){        
  //       return;
  //     }

  //     var serviceResponse = res || {} as ServiceResponse;
  //     if(serviceResponse.hasError != true)
  //     {
  //       this.cityList = serviceResponse.data || {} as Array<City>;
  //     }

  //   });

  // }; 

  submitForm(form:NgForm) {

    if(form.valid != true)
      return;   

    if(isNaN(this.officeId) || this.officeId <=0){
      this.alert = {type: "danger" ,message: "لطفا ابتدا آرایشگاه را از قسمت پروفایل کاربر انتخاب نمائید."};
      return;
    }

    var normalPrice =parseFloat(this.persianNumberService.toEnglish(String(this.serviceInfo.NormalPrice)));
    var discPrice =parseFloat(this.persianNumberService.toEnglish(String(this.serviceInfo.DiscountedPrice)));

    if(discPrice > normalPrice){
      this.alert = {type: "danger" ,message: "قیمت در زمان تخفیف از مبلغ عادی نمی تواند بیشتر باشد."};
      return;
    }

    if(isNaN(this.providerId) || this.providerId <=0){
      this.authService.redirectToLoginPage();
      return;
    }    

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.createServiceSN;
      serviceRequest.data.p1 = this.providerId;
      serviceRequest.data.p2 = this.officeId;
      serviceRequest.data.p3 = this.selectServiceCategory.ServiceCategoryID;
      serviceRequest.data.p4 = normalPrice;
      serviceRequest.data.p5 = discPrice;
      serviceRequest.data.p6 = this.persianNumberService.toEnglish(String(this.serviceInfo.DurationMinute));
      serviceRequest.data.p7 = this.serviceInfo.Title;
      serviceRequest.data.p8 = this.serviceInfo.Description;

  
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;

        if(srvResponse.hasError != true){

          Object.assign(this.args ,this.serviceInfo);
          //this.args.ServiceID = 
          this.close(true);
          //this.router.navigate(["/providerserviceindex"]); 
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });     
  }

  close: (val?: any) => void;

  selectCategory(){

    this.modalService.show(SelectServiceCategoryComponent,this.selectServiceCategory)
    .then(confirmed => {
      if (confirmed) {
      } else {
      }
    });
  }
}
