import { Component, OnInit } from '@angular/core';
import { ProviderCalendarEvent } from 'src/app/shared/models/provider-calendar-event';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';

@Component({
  selector: 'app-calendar-event-create',
  templateUrl: './calendar-event-create.component.html',
  styleUrls: ['./calendar-event-create.component.css']
})
export class CalendarEventCreateComponent implements OnInit {

  fromTime = { hour: 0, minute: 0 };
  toTime = { hour: 0 ,minute: 0 };

  alert: AlertModel;  

  providerId:number;
  officeId:number;
  providerCalendarEvent:ProviderCalendarEvent; 

  constructor(private authService: AuthService
            ,private router: Router) { 

    this.providerCalendarEvent = new ProviderCalendarEvent();
    //this.calendarEvent.EventDate = true;
  }

  ngOnInit() {

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.providerId = userData.ProviderID;
  
    }
    else{

      this.authService.redirectToLoginPage();
      return;
    }
  }

  submitForm(form:NgForm) {

    if(form.valid != true)
      return;

    if(isNaN(this.providerId) || this.providerId <=0){
      this.authService.redirectToLoginPage();
      return;
    }

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.createProviderCalendarEventSN;
      serviceRequest.data.p1 = this.providerId;
      serviceRequest.data.p2 = this.providerCalendarEvent.EventDate;
      serviceRequest.data.p3 = (this.fromTime.hour * 60) + this.fromTime.minute ;
      serviceRequest.data.p4 = (this.toTime.hour * 60) + this.toTime.minute ;
      serviceRequest.data.p5 = this.providerCalendarEvent.Description;
  
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;

        if(srvResponse.hasError != true){

          this.close(true);
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });     
  }

  close: (val?: any) => void;
}
