export class ServiceResponse{
    
    constructor(){}

    hasError: boolean;
    errorNumber: number;

    error: ErrorMessage;

    data: any;
}

export class ErrorMessage{

    message: string;
}