import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToolbarService } from 'src/app/core/services/toolbar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit ,OnDestroy {

  backButtonClickSubscription: Subscription;

  constructor(private toolbarService: ToolbarService
    ,private router: Router) { }

  ngOnInit() {

    this.toolbarService.setTitle('درباره ما');
    this.toolbarService.setBackButtonVisible(true);

    this.backButtonClickSubscription = this.toolbarService.backButtonClick$.subscribe(event=>{
      this.backButtonClicked(event);
    });  
  }

  ngOnDestroy() {

    this.toolbarService.setTitle('');    
    this.toolbarService.setBackButtonVisible(false);

    this.backButtonClickSubscription.unsubscribe();
  }

  backButtonClicked(event: Event){
    this.router.navigate(['/dashboard']);
  } 

  redirectToBlog(){
    // window.location.href="https://www.butyle.ir"
    window.open("https://www.butyle.ir", '_blank');
  }

  redirectToInstagramPage(){

    //window.location.href="https://www.instagram.com/butyle.ir/";
    window.open("https://www.instagram.com/butyle.ir/", '_blank');
  }

  mailToSupport(){
    window.location.href="mailto:support@butyle.ir";
  }

  callSupport(){
    window.location.href="tel:02188734963";
  }
}
