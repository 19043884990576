import { Component, OnInit } from '@angular/core';
import { ProviderCalendar } from 'src/app/shared/models/ProviderCalendar';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { NgForm, FormControl } from '@angular/forms';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant, WeekDaysConstant } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { PersianNumberService } from 'src/app/core/services/persian-number.service';

@Component({
  selector: 'app-calendar-create',
  templateUrl: './calendar-create.component.html',
  styleUrls: ['./calendar-create.component.css']
})
export class CalendarCreateComponent implements OnInit {

  weekDays = new FormControl();
  weekDayList: any;


  
  fromTime = { hour: 0, minute: 0 };
  toTime = { hour: 0 ,minute: 0 };

  alert: AlertModel;  

  providerId:number;
  officeId:number;
  calendarInfo:ProviderCalendar; 

  constructor(private authService: AuthService
    ,private persianNumberService: PersianNumberService
            ,private router: Router) { 
    this.calendarInfo = new ProviderCalendar();
    this.calendarInfo.IsEnable = true;

    this.weekDayList = WeekDaysConstant.weekdaysData;
  }

  ngOnInit() {

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.providerId = userData.ProviderID;

      if(userData.Offices.length > 0)
        this.officeId = userData.Offices[0].OfficeID;
      else
      {
        this.alert = {type: "danger" ,message: "لطفا ابتدا آرایشگاه را از قسمت پروفایل کاربر انتخاب نمائید."};
      }   
    }
    else{

      this.authService.redirectToLoginPage();
      return;
    }
  }

  submitForm(form:NgForm) {

    if(form.valid != true)
      return;      

    if(this.weekDays.value == null || this.weekDays.value.length <= 0){
      
      return;
    }

    if(isNaN(this.officeId) || this.officeId <=0){
      this.alert = {type: "danger" ,message: "لطفا ابتدا آرایشگاه را از قسمت پروفایل کاربر انتخاب نمائید."};
      return;
    }

    if(isNaN(this.providerId) || this.providerId <=0){
      this.authService.redirectToLoginPage();
      return;
    }    

    var fromTimeMinute =parseFloat(this.persianNumberService.toEnglish(String(this.fromTime.minute)));
    var fromTimeHour =parseFloat(this.persianNumberService.toEnglish(String(this.fromTime.hour)));

    var toTimeMinute =parseFloat(this.persianNumberService.toEnglish(String(this.toTime.minute)));
    var toTimeHour =parseFloat(this.persianNumberService.toEnglish(String(this.toTime.hour)));    

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.createProviderCalendarSN;
      serviceRequest.data.p1 = this.providerId;
      serviceRequest.data.p2 = this.officeId;
      serviceRequest.data.p3 = this.weekDays.value;
      serviceRequest.data.p4 = (fromTimeHour * 60) + fromTimeMinute ;//this.calendarInfo.StartDayMinute;
      serviceRequest.data.p5 = (toTimeHour * 60) + toTimeMinute ;//this.calendarInfo.EndDayMinute;
      serviceRequest.data.p6 = this.calendarInfo.IsInDiscount;
  
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;

        if(srvResponse.hasError != true){

          this.close(true);
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });     
  }

  close: (val?: any) => void;

}
