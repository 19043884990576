import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  OnDestroy,
} from "@angular/core";
import { ToolbarService } from "../../services/toolbar.service";
import { Subject, Subscription } from "rxjs";

@Component({
  selector: "app-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.css"],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  title: String;
  backButtonVisibleIND: boolean;
  newButtonVisibleIND: boolean;
  editButtonVisibleIND: boolean;
  customTextButtonVisibleIND: boolean;
  customTextButtonCaption: string;
  menuFilterVisibleIND: boolean;
  menuSortVisibleIND: boolean;

  @Output() toggleSidenav = new EventEmitter<void>();

  titleSubscription: Subscription;
  backButtonVisibleSubscription: Subscription;
  newButtonVisibleSubscription: Subscription;
  editButtonVisibleSubscription: Subscription;
  customTextButtonVisibleSubscription: Subscription;
  customTextButtonCaptionSubscription: Subscription;
  menuFilterVisibleSubscription: Subscription;
  menuSortVisibleSubscription: Subscription;

  constructor(private toolbarService: ToolbarService) {}

  ngOnInit() {
    this.titleSubscription = this.toolbarService
      .getTitle()
      .subscribe((appTitle) => (this.title = appTitle));
    this.backButtonVisibleSubscription = this.toolbarService
      .getBackButtonVisible()
      .subscribe((visibleIND) => (this.backButtonVisibleIND = visibleIND));
    this.newButtonVisibleSubscription = this.toolbarService
      .getNewButtonVisible()
      .subscribe((visibleIND) => (this.newButtonVisibleIND = visibleIND));

    this.editButtonVisibleSubscription = this.toolbarService
      .getEditButtonVisible()
      .subscribe((visibleIND) => (this.editButtonVisibleIND = visibleIND));

    this.customTextButtonVisibleSubscription = this.toolbarService
      .getCustomTextButtonVisible()
      .subscribe(
        (visibleIND) => (this.customTextButtonVisibleIND = visibleIND)
      );

    this.customTextButtonCaptionSubscription = this.toolbarService
      .getCustomTextButtonCaption()
      .subscribe((caption) => (this.customTextButtonCaption = caption));

    this.menuFilterVisibleSubscription = this.toolbarService
      .getMenuFilterVisibleIND()
      .subscribe((visibleIND) => (this.menuFilterVisibleIND = visibleIND));
    this.menuSortVisibleSubscription = this.toolbarService
      .getMenuSortVisibleIND()
      .subscribe((visibleIND) => (this.menuSortVisibleIND = visibleIND));
  }

  ngOnDestroy() {
    this.titleSubscription.unsubscribe();
    this.backButtonVisibleSubscription.unsubscribe();
    this.newButtonVisibleSubscription.unsubscribe();
    this.editButtonVisibleSubscription.unsubscribe();

    this.customTextButtonVisibleSubscription.unsubscribe();
    this.customTextButtonCaptionSubscription.unsubscribe();

    this.menuFilterVisibleSubscription.unsubscribe();
    this.menuSortVisibleSubscription.unsubscribe();
  }

  backButton() {
    this.toolbarService.backButtonClicked(event);
  }

  newItemClicked(event: Event) {
    this.toolbarService.newButtonClicked(event);
  }

  editButtonClicked(event: Event) {
    this.toolbarService.editButtonClicked(event);
  }

  customTextButtonClicked(event: Event) {
    this.toolbarService.customTextButtonClicked(event);
  }

  menuFilterClicked(event: Event) {
    this.toolbarService.menuFilterClicked(event);
  }

  menuSortClicked(event: Event) {
    this.toolbarService.menuSortClicked(event);
  }
}
