import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToolbarService } from 'src/app/core/services/toolbar.service';
import { Router } from '@angular/router';
import { ProviderMessage } from 'src/app/shared/models/provider-message';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { ModalService } from 'src/app/core/modal.service';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { MessageDetailComponent } from '../message-detail/message-detail.component';

@Component({
  selector: 'app-message-index',
  templateUrl: './message-index.component.html',
  styleUrls: ['./message-index.component.css']
})
export class MessageIndexComponent implements OnInit ,OnDestroy {

  alert: AlertModel;  

  providerId:number;
  providerMessages:Array<ProviderMessage>;

  backButtonClickSubscription: Subscription;

  constructor(private authService: AuthService 
             ,private router: Router
             ,private modalService: ModalService
             ,private toolbarService: ToolbarService ) {}

  ngOnInit() {

    this.toolbarService.setTitle('پیام ها');
    this.toolbarService.setBackButtonVisible(true);

    this.backButtonClickSubscription = this.toolbarService.backButtonClick$.subscribe(event=>{
      this.backButtonClicked(event);
    });         

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.providerId = userData.ProviderID;  
      this.getProviderMessages();
    }
    else{

      this.authService.redirectToLoginPage();
      return;
    }
  }

  ngOnDestroy() {

    this.toolbarService.setTitle('');    
    this.toolbarService.setBackButtonVisible(false);
    this.toolbarService.setNewButtonVisible(false);
    this.toolbarService.setMenuFilterVisibleIND(false);
    this.toolbarService.setMenuSortVisibleIND(false);

    this.backButtonClickSubscription.unsubscribe();
  }

  backButtonClicked(event: Event){
    this.router.navigate(['/dashboard']);
  }

  getProviderMessages(){
    
    var req=new ServiceRequest();
    req.data.sn = ServiceConstant.getProviderMessagesSN;
    req.data.p1 = this.providerId;
    //req.data.p2 = searchText;
    // req.data.p3 = sortType;
    // req.data.p4 = filterType;
    // req.data.p5 = startRowIndex;
    // req.data.p6 = recordCount;

    this.authService.consumeService(req).subscribe((res)=>{
              
      if(!res){
        return;
      }

      var serviceResponse = res || {} as ServiceResponse;

      if(serviceResponse.hasError != true)
      {
        this.providerMessages = serviceResponse.data || {} as Array<ProviderMessage>;      
      }
    });     
  } 

  redirectToDetailView(providerMessageID: number){

    this.modalService.show(
      MessageDetailComponent,this.providerMessages.find(x => x.MessageID == providerMessageID));
  }

  confirmDeleteRecord(providerMessageID: number){

    this.modalService.show(
      ConfirmModalComponent,
      {
        title: "سوال", message: "آیا از حذف اطلاعات اطمینان دارید؟"
      })
      .then(confirmed => {
        if (confirmed) {

          this.deleteProviderMessage(providerMessageID);
        } else {

          //this.confirmResult = "Canceled!";          
        }
      });
  }

deleteProviderMessage(providerMessageID: number){

    if(isNaN(this.providerId) || this.providerId <=0){
      this.authService.redirectToLoginPage();
      return;
    }    

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.deleteProviderMessageSN;
      serviceRequest.data.p1 = providerMessageID;
  
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;
        
        if(srvResponse.hasError != true){

          const itemIndex = this.providerMessages.findIndex(obj=> obj["MessageID"] == providerMessageID);
          this.providerMessages.splice(itemIndex, 1);
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });      

  }
}
