import { Component, OnInit, Inject, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { ProviderInfo } from 'src/app/shared/models/provider-info';
import { AuthService } from 'src/app/core/services/auth.service';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { HttpClient } from '@angular/common/http';
import { ModalService } from 'src/app/core/modal.service';
import { EditBankComponent } from '../edit-bank/edit-bank.component';
import { ServiceConstant } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { APP_CONFIG, IAppConfig } from 'src/app/core/services/app.config';
import { ToolbarService } from 'src/app/core/services/toolbar.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { EditProfileComponent } from '../edit-profile/edit-profile.component';
import { MatTabChangeEvent } from '@angular/material';
import { ProviderHeaderPicture } from 'src/app/shared/models/ProviderHeaderPicture';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { ActivationRequestComponent } from '../activation-request/activation-request.component';

enum ProfileTabIndex {
  PublicTab = 0,
  PictureTab = 1,
  ReviewTab = 2,
  BankInfoTab= 3,
}

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit ,OnDestroy {
  
  @ViewChild('providerHeaderPictureInputFile', { static: false }) providerHeaderPictureInputFile:ElementRef<HTMLElement>;

  alert: AlertModel;  
  fileToUpload: File = null;
  profilePictureSrc:String;

  domainImagePrefix: String;

  providerId:number;
  providerInfo:ProviderInfo;

  editToolbarButtonSubscription: Subscription;
  addPictureToolbarButtonSubscription: Subscription;
  backButtonClickSubscription: Subscription;

  constructor(private authService: AuthService,
  @Inject(APP_CONFIG) private appConfig: IAppConfig,
              private http: HttpClient,
              private toolbarService: ToolbarService,
              private modalService: ModalService,
              private router: Router
              ) { 

                this.domainImagePrefix = `${this.appConfig.imageEndpoint}/`;
              }

  ngOnInit() {

    this.setupToolbar();

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.providerId = userData.ProviderID;
      this.getProviderProfile();
    }
  }

  ngOnDestroy() {

    this.editToolbarButtonSubscription.unsubscribe();
    this.addPictureToolbarButtonSubscription.unsubscribe();
    this.backButtonClickSubscription.unsubscribe();

    this.toolbarService.setBackButtonVisible(false);
    this.toolbarService.setEditButtonVisible(false);
    this.toolbarService.setNewButtonVisible(false);
  }

  setupToolbar(){

    this.toolbarService.setTitle('');
    this.toolbarService.setBackButtonVisible(true);
    this.toolbarService.setEditButtonVisible(true);
    this.toolbarService.setNewButtonVisible(false);
    this.toolbarService.setMenuFilterVisibleIND(false);
    this.toolbarService.setMenuSortVisibleIND(false);

    this.editToolbarButtonSubscription = this.toolbarService.editButtonClick$.subscribe(event=>{
      this.editProfileButtonClicked(event);
    });

    this.addPictureToolbarButtonSubscription = this.toolbarService.newItemClick$.subscribe(event=>{
      this.newPictureButtonClicked(event);
    });    

    this.backButtonClickSubscription = this.toolbarService.backButtonClick$.subscribe(event=>{
      this.backButtonClicked(event);
    }); 
  }

  getProviderProfile(){

    this.authService.getProviderInfoAndSetLocalData(this.providerId).subscribe((res)=>{
              
      if(!res){        
        return;
      }

      this.providerInfo = res;

      //console.log(res);

      if(res.ProfilePicturePath){
        this.setImageSrc(res.ProfilePicturePath);
      }
      else{
        this.profilePictureSrc = "";
      }

    }); 
  }; 

  setImageSrc(imgpath:string){

    this.profilePictureSrc = `${this.appConfig.imageEndpoint}/${imgpath}`;
    this.profilePictureSrc= this.profilePictureSrc  + '?' + (new Date()).getTime();
  }

  onFileSelected(event)
  {
    
    this.fileToUpload = <File>event.target.files[0];

    var reader = new FileReader();
    
    reader.onload = function (e) {
    };
    reader.readAsDataURL(<File>event.target.files[0]);
    
    this.uploadProfilePicture();
  }

  uploadProfilePicture()
  {

    var srvRequest= new ServiceRequest();
    srvRequest.data.sn = ServiceConstant.updateProviderProfilePictureSN;
    srvRequest.data.p1 = this.providerId;

    this.authService.uploadImageService(srvRequest,this.fileToUpload).subscribe((response)=>{

      if (!response) {

        this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

        return null;
      }

      var srvResponse = response || {} as ServiceResponse;

      if(srvResponse.hasError != true){
        
        var imgPath = srvResponse.data ||{} as string;
        if(imgPath){
          this.setImageSrc(imgPath);
        }

        this.alert = {type: "success" ,message: "اطلاعات با موفقت ثبت گردید"};  
      }
      else if(srvResponse.error.message)
        this.alert = {type: "danger" ,message: srvResponse.error.message};   
      else
        this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
    });
  }

  onProviderHeaderPictureSelected(event)
  {
    
    this.fileToUpload = <File>event.target.files[0];

    var reader = new FileReader();
    
    reader.onload = function (e) {
    };
    reader.readAsDataURL(<File>event.target.files[0]);
    
    this.uploadProviderHeaderPicctureHeader();
  }

  uploadProviderHeaderPicctureHeader()
  {

    var srvRequest= new ServiceRequest();
    srvRequest.data.sn = ServiceConstant.addProviderHeaderPictureSN;
    srvRequest.data.p1 = this.providerId;

    this.authService.uploadImageService(srvRequest,this.fileToUpload).subscribe((response)=>{

      if (!response) {

        this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

        return null;
      }

      var srvResponse = response || {} as ServiceResponse;

      if(srvResponse.hasError != true){
        
         var newProviderHeaderPicture = srvResponse.data || {} as ProviderHeaderPicture;

         if(newProviderHeaderPicture.ProviderHeaderPictureID >0)
         {
            if(this.providerInfo.HeaderPictures == undefined)
            this.providerInfo.HeaderPictures =new Array<ProviderHeaderPicture>();            
            this.providerInfo.HeaderPictures.push(newProviderHeaderPicture);

            newProviderHeaderPicture.HeaderPicturePath +=  '?' + (new Date()).getTime();
         }

        this.alert = {type: "success" ,message: "اطلاعات با موفقت ثبت گردید"};  
      }
      else if(srvResponse.error.message)
        this.alert = {type: "danger" ,message: srvResponse.error.message};   
      else
        this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
    });
  }

  confirmDeleteProviderHeaderPicture(providerHeaderPictureID:number){

    if(providerHeaderPictureID <= 0)
      return;

    this.modalService.show(
      ConfirmModalComponent,
      {
        title: "سوال", message: "آیا از حذف تصویر اطمینان دارید ؟"
      })
      .then(confirmed => {
        if (confirmed) {

          this.removeProviderHeaderPicture(providerHeaderPictureID);
        } else {

          //this.confirmResult = "Canceled!";          
        }
      });
  }
  
  removeProviderHeaderPicture(providerHeaderPictureID: number){

    var serviceRequest= new ServiceRequest();    
    serviceRequest.data.sn = ServiceConstant.deleteProviderHeaderPictureSN;
    serviceRequest.data.p1 = providerHeaderPictureID;

    this.authService.consumeService(serviceRequest).subscribe((response)=>{

      if (!response) {

        this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

        return null;
      }

      var srvResponse = response || {} as ServiceResponse;
      
      if(srvResponse.hasError != true){

        const itemIndex = this.providerInfo.HeaderPictures.findIndex(obj=> obj["ProviderHeaderPictureID"] == providerHeaderPictureID);
        if(itemIndex >= 0){
          this.providerInfo.HeaderPictures.splice(itemIndex, 1);
        }
                
      }
      else if(srvResponse.error.message)
        this.alert = {type: "danger" ,message: srvResponse.error.message};   
      else
        this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
    });   
}

setDefaultProviderHeaderPicture(providerHeaderPictureID: number){

  var serviceRequest= new ServiceRequest();    
  serviceRequest.data.sn = ServiceConstant.setDefaultProviderHeaderPictureSN;
  serviceRequest.data.p1 = this.providerInfo.ProviderID;
  serviceRequest.data.p2 = providerHeaderPictureID;

  this.authService.consumeService(serviceRequest).subscribe((response)=>{

    if (!response) {

      this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

      return null;
    }

    var srvResponse = response || {} as ServiceResponse;
    
    if(srvResponse.hasError != true){

      this.providerInfo.HeaderPictures.forEach(c=>c.IsDefault = c.ProviderHeaderPictureID == providerHeaderPictureID );
      // const itemIndex = this.providerInfo.HeaderPictures.findIndex(obj=> obj["ProviderHeaderPictureID"] == providerHeaderPictureID);
      // if(itemIndex >= 0){

      //   this.providerInfo.HeaderPictures[itemIndex].IsDefault=true;
      // }
              
    }
    else if(srvResponse.error.message)
      this.alert = {type: "danger" ,message: srvResponse.error.message};   
    else
      this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
  });   
}


  editBankInfo(event: Event){

    this.modalService.show(EditBankComponent,this.providerInfo)
    .then(confirmed => {
      if (confirmed) {

      } else {
      }
    });
  }

  backButtonClicked(event: Event){

    this.router.navigate(["/dashboard"]);
  }

  editProfileButtonClicked(event: Event){

    this.modalService.show(EditProfileComponent ,this.providerInfo)
      .then(confirmed => {
        if (confirmed) {

        } else {

          //this.confirmResult = "Canceled!";    
          console.log("cancel");      
        }
      });
  }

  newPictureButtonClicked(event: Event){

    this.providerHeaderPictureInputFile.nativeElement.click();
  }

  onTabChange(event: MatTabChangeEvent){

    this.addPictureToolbarButtonSubscription.unsubscribe();
    this.editToolbarButtonSubscription.unsubscribe();
    
    this.toolbarService.setEditButtonVisible(false);
    this.toolbarService.setNewButtonVisible(false);

    if(event.index == ProfileTabIndex.PublicTab){
      
      this.toolbarService.setEditButtonVisible(true);
      this.editToolbarButtonSubscription = this.toolbarService.editButtonClick$.subscribe(event=>{
        this.editProfileButtonClicked(event);
      });
    }
    else if(event.index == ProfileTabIndex.PictureTab){    
      
      this.toolbarService.setNewButtonVisible(true);
      this.addPictureToolbarButtonSubscription = this.toolbarService.newItemClick$.subscribe(event=>{
        this.newPictureButtonClicked(event);
      });      
    }
    else if(event.index == ProfileTabIndex.ReviewTab){
      
    }
    else if(event.index == ProfileTabIndex.BankInfoTab){

      this.toolbarService.setEditButtonVisible(true);

      this.editToolbarButtonSubscription = this.toolbarService.editButtonClick$.subscribe(event=>{
        this.editBankInfo(event);
      });
    }      
  }

  activationRequest(event: Event){
    
    this.modalService.show(ActivationRequestComponent ,this.providerInfo)
      .then(confirmed => {
        if (confirmed) {

        } else {

          //this.confirmResult = "Canceled!";    
          console.log("cancel");      
        }
      });    
  }
}