import { ProviderService } from "./ProviderService";

export class Campaign {
  constructor() {}

  CampaignID: number;
  Title: string;
  Description: string;

  CreateDateTime: number; //long

  DiscountType: number; //int
  TotalDiscount: number; //double
  ProviderDiscount: number;
  AppDiscount: number;
  BookMinPrice: number;
  BookMaxPrice: number;

  StartJoinDateTime: number; //long
  EndJoinDateTime: number;
  StartDateTime: number;
  EndDateTime: number;

  Services: Array<ProviderService>; //List<Service>
}
