import { Component, OnDestroy, OnInit } from "@angular/core";
import { MediaChange, MediaObserver  } from "@angular/flex-layout";
import { Subscription } from "rxjs";

import { Direction } from '@angular/cdk/bidi';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.css"]
})
export class SidenavComponent implements OnInit, OnDestroy {

  dir: Direction;
  displayName: string="";

  isScreenSmall = false;
  watcher: Subscription;

  constructor(private media: MediaObserver
             ,private router: Router
             ,private authService:AuthService) {

    this.watcher = media.media$.subscribe((change: MediaChange) => {
      this.isScreenSmall = change.mqAlias === "xs";
    });
  }

  ngOnInit() {

    this.dir ="rtl";
    var userData = this.authService.getAuthUser();

    if(userData.ProviderID > 0)
    {
      this.displayName = (userData.FirstName||'') +" "+ (userData.LastName ||'');
    }

    // if(!this.displayName.trim())
    //   this.router.navigate(["/profile"]);
  }

  ngOnDestroy() {
    this.watcher.unsubscribe();
  }
}
