import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProviderMessageRoutingModule } from './provider-message-routing.module';
import { ProviderMessageAppComponent } from './provider-message-app/provider-message-app.component';
import { MessageIndexComponent } from './components/message-index/message-index.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { MessageDetailComponent } from './components/message-detail/message-detail.component';

@NgModule({
  declarations: [
    ProviderMessageAppComponent, 
    MessageIndexComponent, MessageDetailComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule.forRoot(),    
    ProviderMessageRoutingModule
  ]
})
export class ProviderMessageModule { }
