import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FinancialAppComponent } from './financial-app/financial-app.component';
import { AuthGuard } from '../core/services/auth.guard';
import { FinancialIndexComponent } from './components/financial-index/financial-index.component';

const routes: Routes = [
  { path: '', redirectTo: 'financialindex' ,pathMatch: 'full' },
  {
    path: "", component: FinancialAppComponent,
    children: [
      { path: "financialindex", component: FinancialIndexComponent },
    ]
    ,canActivate: [ AuthGuard ]
    ,canActivateChild: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FinancialRoutingModule { }
