import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProviderOfficeRoutingModule } from './provider-office-routing.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';

import { ProviderOfficeAppComponent } from './provider-office-app/provider-office-app.component';
import { OfficeIndexComponent } from './components/office-index/office-index.component';
import { OfficeCreateComponent } from './components/office-create/office-create.component';
import { OfficeEditComponent } from './components/office-edit/office-edit.component';
import { OfficeDetailComponent } from './components/office-detail/office-detail.component';
import { OfficeLocationComponent } from './components/office-location/office-location.component';

@NgModule({
  declarations: [ProviderOfficeAppComponent, OfficeIndexComponent, OfficeCreateComponent, OfficeEditComponent, OfficeDetailComponent, OfficeLocationComponent],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule.forRoot(),
    ProviderOfficeRoutingModule
  ]
})
export class ProviderOfficeModule { }
