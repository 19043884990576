import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';

import { CalendarEventRoutingModule } from './calendar-event-routing.module';
import { CalendarEventAppComponent } from './calendar-event-app/calendar-event-app.component';
import { CalendarEventIndexComponent } from './components/calendar-event-index/calendar-event-index.component';
import { CalendarEventCreateComponent } from './components/calendar-event-create/calendar-event-create.component';
import { CalendarEventEditComponent } from './components/calendar-event-edit/calendar-event-edit.component';

@NgModule({
  declarations: [CalendarEventAppComponent, CalendarEventIndexComponent, CalendarEventCreateComponent, CalendarEventEditComponent],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule.forRoot(),
    CalendarEventRoutingModule
  ]
})
export class CalendarEventModule { }
