import { Component, OnInit } from '@angular/core';
import { ProviderCalendar } from 'src/app/shared/models/ProviderCalendar';
import { AuthService } from 'src/app/core/services/auth.service';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant, WeekDaysConstant } from 'src/app/core/models/service-constant';
import { FormControl, NgForm } from '@angular/forms';
import { ServiceResponse } from 'src/app/core/models/service-response';

@Component({
  selector: 'app-calendar-edit',
  templateUrl: './calendar-edit.component.html',
  styleUrls: ['./calendar-edit.component.css']
})
export class CalendarEditComponent implements OnInit {

  weekDays = new FormControl();
  weekDayList: any;

  alert: AlertModel;  

  providerCalendarID:number;
  providerId:number;

  args:ProviderCalendar;
  fromTime = { hour: 0, minute: 0 };
  toTime = { hour: 0 ,minute: 0 }; 
  isInDiscount = false;
  isEnable = false;

  constructor(private authService: AuthService) { 
    this.weekDayList = WeekDaysConstant.weekdaysData;
  }

  ngOnInit() {   

    this.isInDiscount = this.args.IsInDiscount;
    this.isEnable = this.args.IsEnable;

    this.fromTime.hour=Math.floor(this.args.StartDayMinute/60);
    this.fromTime.minute= this.args.StartDayMinute - (this.fromTime.hour * 60);

    this.toTime.hour=Math.floor(this.args.EndDayMinute/60);
    this.toTime.minute=this.args.EndDayMinute - (this.toTime.hour * 60);

    // var userData = this.authService.getAuthUser();

    // if(userData != null && userData.ProviderID > 0)
    // {
    //   this.providerId = userData.ProviderID; 
    // }
    // else{

    //   this.authService.redirectToLoginPage();
    //   return;
    // } 
  } 

  close: (val?: any) => void;

  submitForm(form:NgForm){

    if(form.valid != true)
      return; 

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.editProviderCalendarSN;

      serviceRequest.data.p1 = this.args.ProviderCalendarID;
      serviceRequest.data.p2 = this.args.WeekDay;
      serviceRequest.data.p3 = (this.fromTime.hour * 60) + this.fromTime.minute;
      serviceRequest.data.p4 =  (this.toTime.hour * 60) + this.toTime.minute;
      serviceRequest.data.p5 =  this.isInDiscount;
      serviceRequest.data.p6 = this.isEnable;
  
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;

        if(srvResponse.hasError != true){

          this.args.IsInDiscount = this.isInDiscount;
          this.args.IsEnable = this.isEnable;
          this.args.StartDayMinute = (this.fromTime.hour * 60) + this.fromTime.minute;
          this.args.EndDayMinute =  (this.toTime.hour * 60) + this.toTime.minute;
          this.close();
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });

  }

}
