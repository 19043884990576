import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { MapLocation } from 'src/app/shared/models/MapLocation';

@Component({
  selector: 'app-office-location',
  templateUrl: './office-location.component.html',
  styleUrls: ['./office-location.component.css']
})
export class OfficeLocationComponent implements OnInit ,OnDestroy ,AfterViewInit {

  map: mapboxgl.Map;
  marker: mapboxgl.Marker;
  justReadnly: boolean;

  style = 'mapbox://styles/mapbox/streets-v11';
  lat = 35.70173651488548;
  lng = 51.43014083798158;

  args:MapLocation;
  options: boolean;

  constructor() { 
    
    Object.getOwnPropertyDescriptor(mapboxgl, "accessToken").set(environment.mapbox.accessToken);

    try{
      mapboxgl.setRTLTextPlugin('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.0/mapbox-gl-rtl-text.js' ,error=>{});
    }    
    catch{

    }    
  }

  ngOnInit() {

    if(this.args){
      this.lat =this.args.Lat;
      this.lng =this.args.Lng;
    }
    this.justReadnly = this.options == true ? true : false;
    
  }

  ngAfterViewInit(){

    this.initializeMap();
  }

  ngOnDestroy(): void {}


  private initializeMap() {

    /// locate the user


    this.buildMap()

  }  

  buildMap() {

    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: 14,
      center: [this.lng, this.lat],      
    });

    this.setLnglatAndGoToLocation(this.lng ,this.lat);

    if (navigator.geolocation && this.args.Lat == 0) {

      navigator.geolocation.getCurrentPosition(position => {
       this.lat = position.coords.latitude;
       this.lng = position.coords.longitude;
       this.map.flyTo({
         center: [this.lng, this.lat]
       });
     });
   }
  //  else if(this.args.Lat == 0)
  //  {
  //    this.lat = 35.70173651488548;
  //    this.lng = 51.43014083798158;
  //    this.map.flyTo({
  //      center: [this.lng, this.lat]
  //    });
  //  }    


    /// Add map controls
    //this.map.addControl(new mapboxgl.NavigationControl());
    


    //// Add Marker on Click
    if(this.justReadnly != true){
      
      this.map.on('click', (event) => {
        this.lat=event.lngLat.lat;
        this.lng = event.lngLat.lng;
  
        this.marker.setLngLat([this.lng, this.lat]);
      });
    }

    /// Add realtime firebase data on map load
    this.map.on('load', (event) => {

      this.map.resize();

      var draggable = this.justReadnly ? false : true;

      this.marker = new mapboxgl.Marker()
          .setLngLat([this.lng, this.lat])
          .addTo(this.map)
          .setDraggable(draggable)
          ;

      this.marker.on('dragend', (event)=>{

        var lngLat = this.marker.getLngLat();
        this.lat = lngLat.lat;
        this.lng = lngLat.lng;
      });

    });
  } 

  setLnglatAndGoToLocation(lng: number ,lat: number){

    if(lat== 0 || lng ==0){

      this.lat = 35.70173651488548;
      this.lng = 51.43014083798158;
    }
    else{

      this.lat = lat;
      this.lng= lng;
    }    
    
    this.map.flyTo({
      center: [this.lng, this.lat]
    });
  }

  selectLocation(){

    this.args.Lat= this.lat;
    this.args.Lng = this.lng;
    
    this.close(true);
  }

  navToCurrentPosition(){
    if (navigator.geolocation) {

      navigator.geolocation.getCurrentPosition(position => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.marker.setLngLat([this.lng, this.lat]);
        this.map.flyTo({
          center: [this.lng, this.lat]
        });
      });      
    }
  }

  close: (val?: any) => void;
}
