import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MaterialModule } from "./material.module";
import { SharedBootstrapModule } from "./shared.bootstrap.module";

import { ConfirmModalComponent } from "./confirm-modal/confirm-modal.component";
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { ShamsiDateFormatPipe } from './pipes/shamsi-date-format.pipe';
import { PerNumberPipe } from './pipes/per-number.pipe';
import { EnNumPipe } from './pipes/en-num.pipe';
import { FaNumPipe } from './pipes/fa-num.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    SharedBootstrapModule
  ],
  entryComponents: [
    // All components about to be loaded "dynamically" need to be declared in the entryComponents section.
    ConfirmModalComponent
  ],
  declarations: [
    // common and shared components/directives/pipes between more than one module and components will be listed here.
    ConfirmModalComponent,
    PerNumberPipe,
    EnNumPipe,
    FaNumPipe,
    TimeFormatPipe,
    ShamsiDateFormatPipe
  ],
  exports: [
    // common and shared components/directives/pipes between more than one module and components will be listed here.
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    PerNumberPipe,
    EnNumPipe,
    FaNumPipe,
    TimeFormatPipe,
    ShamsiDateFormatPipe,
    SharedBootstrapModule
  ]
  /* No providers here! Since they’ll be already provided in AppModule. */
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    // Forcing the whole app to use the returned providers from the AppModule only.
    return {
      ngModule: SharedModule,
      providers: [ /* All of your services here. It will hold the services needed by `itself`. */]
    };
  }
}