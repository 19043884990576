import { Component, OnInit } from '@angular/core';
import { ProviderBookCancelReason } from 'src/app/shared/models/provider-book-cancel-reason';
import { Book } from 'src/app/shared/models/Book';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant } from 'src/app/core/models/service-constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MapLocation } from 'src/app/shared/models/MapLocation';

@Component({
  selector: 'app-book-cancel',
  templateUrl: './book-cancel.component.html',
  styleUrls: ['./book-cancel.component.css']
})
export class BookCancelComponent implements OnInit {

  alert: AlertModel;  

  bookId: number;
  providerId: number;
  providerBookCancelReasonID: number;
  providerBookCancelReasonDescription: string;
  location: MapLocation;

  cancelReasonList: Array<ProviderBookCancelReason>;

  args:Book; //bookid

  constructor(private authService: AuthService,private router: Router) { }

  ngOnInit() {
    this.bookId= this.args.BookID;
    this.providerId = this.args.ProviderID;

    this.fillLookupCancelReasonList();

    this.providerBookCancelReasonDescription = "";
    this.location = new MapLocation();
    this.location.Lat = 0;
    this.location.Lng = 0;
  }

  fillLookupCancelReasonList(){ 

    var cancelReasonRequest = new ServiceRequest();
    cancelReasonRequest.data.sn = ServiceConstant.getProviderBookCancelReasonsSN;

    this.authService.consumeService(cancelReasonRequest).subscribe((res)=>{
              
      if(!res){        
        return;
      }

      var serviceResponse = res || {} as ServiceResponse;
      if(serviceResponse.hasError != true)
      {
        this.cancelReasonList = serviceResponse.data || {} as Array<ProviderBookCancelReason>;
      }

    });

  }; 

  close: (val?: any) => void;

  submitForm(form:NgForm){

    if(form.valid != true)
      return; 


      navigator.geolocation.getCurrentPosition(position => {
        this.location.Lat = position.coords.latitude;
        this.location.Lng = position.coords.longitude;
      });



      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.providerBookCancelSN;

      serviceRequest.data.p1 = this.providerId;
      serviceRequest.data.p2 = this.bookId;
      serviceRequest.data.p3 = this.providerBookCancelReasonID;
      serviceRequest.data.p4 = this.providerBookCancelReasonDescription;
      serviceRequest.data.p5 = this.location;
  
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;

        if(srvResponse.hasError != true){

          this.close(true);
          
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });
  }
}