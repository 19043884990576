import { Component, OnInit } from '@angular/core';
import { Office } from 'src/app/shared/models/Office';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { MapLocation } from 'src/app/shared/models/MapLocation';
import { City } from 'src/app/shared/models/City';
import { ModalService } from 'src/app/core/modal.service';
import { OfficeLocationComponent } from '../office-location/office-location.component';

@Component({
  selector: 'app-office-create',
  templateUrl: './office-create.component.html',
  styleUrls: ['./office-create.component.css']
})
export class OfficeCreateComponent implements OnInit {

  alert: AlertModel;  

  providerId:number;
  officeInfo:Office; 
  location: MapLocation;
  provinceName: any;
  provinceId: number;
  cityList: Array<City>;

  constructor(private authService: AuthService
    ,private modalService: ModalService
            ,private router: Router) { 

    this.officeInfo = new Office();
    this.location = new MapLocation();
    this.location.Lat = 0;
    this.location.Lng = 0;
  }

  ngOnInit() {

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.providerId = userData.ProviderID;  
      this.provinceName =userData.ProvinceName;
      this.provinceId = userData.ProvinceID;

      this.fillLookupCityList();
    }
    else{

      this.authService.redirectToLoginPage();
      return;
    }
  }

  fillLookupCityList(){

    if(isNaN(this.providerId) || this.providerId <=0){
      this.authService.redirectToLoginPage();
      return;
    }  

    if( isNaN(this.provinceId) || this.provinceId <=0)
      return;

    var officeRequest = new ServiceRequest();
    officeRequest.data.sn = ServiceConstant.getCitiesByProvinceIDSN;
    officeRequest.data.p1 =this.provinceId;

    this.authService.consumeService(officeRequest).subscribe((res)=>{
              
      if(!res){        
        return;
      }

      var serviceResponse = res || {} as ServiceResponse;
      if(serviceResponse.hasError != true)
      {
        this.cityList = serviceResponse.data || {} as Array<City>;
      }

    });

  }; 

  submitForm(form:NgForm) {

    if(form.valid != true)
      return;

    if(isNaN(this.providerId) || this.providerId <=0){
      this.authService.redirectToLoginPage();
      return;
    }    

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.createOfficeSN;
      serviceRequest.data.p1 = this.providerId;
      serviceRequest.data.p2 = this.officeInfo.CityID;
      serviceRequest.data.p3 = this.officeInfo.Name;
      serviceRequest.data.p4 = this.officeInfo.PhoneNumbers;
      serviceRequest.data.p5 = this.location; //GeoLocation location
      serviceRequest.data.p6 = this.officeInfo.Address;
      serviceRequest.data.p7 = this.officeInfo.Description;
      serviceRequest.data.p8 = this.officeInfo.ManagerName;
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;

        if(srvResponse.hasError != true){

          this.close(true);
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });     
  }

  selectLocation(){

    this.modalService.show(
      OfficeLocationComponent,this.location)
      .then(confirmed => {
        if (confirmed) {

          // console.log("confirmed");
          // console.log(this.location);
        } else {

          //this.confirmResult = "Canceled!";    
          //console.log("cancel");      
        }
      });
  }

  close: (val?: any) => void;

}
