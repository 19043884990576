import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserProfileRoutingModule } from './user-profile-routing.module';
import { UserProfileAppComponent } from './user-profile-app/user-profile-app.component';
import { IndexComponent } from './components/index/index.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { ReviewComponent } from './components/review/review.component';
import { ViewProfileComponent } from './components/view-profile/view-profile.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { EditBankComponent } from './components/edit-bank/edit-bank.component';
import { ActivationRequestComponent } from './components/activation-request/activation-request.component';

@NgModule({
  declarations: [
    UserProfileAppComponent, 
    IndexComponent, ReviewComponent, ViewProfileComponent, EditProfileComponent, EditBankComponent, ActivationRequestComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule.forRoot(),
    UserProfileRoutingModule
  ]
})
export class UserProfileModule { }
