import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalendarEventAppComponent } from './calendar-event-app/calendar-event-app.component';
import { CalendarEventIndexComponent } from './components/calendar-event-index/calendar-event-index.component';
import { CalendarEventCreateComponent } from './components/calendar-event-create/calendar-event-create.component';
import { AuthGuard } from '../core/services/auth.guard';
import { CalendarEventEditComponent } from './components/calendar-event-edit/calendar-event-edit.component';

const routes: Routes = [
  { path: '', redirectTo: 'calendarevent' ,pathMatch: 'full' },
  {
    path: "", component: CalendarEventAppComponent,
    children: [
      { path: "calendarevent", component: CalendarEventIndexComponent },
      { path: "calendareventcreate", component: CalendarEventCreateComponent },
      { path: "calendareventedit", component: CalendarEventEditComponent },
    ]
    ,canActivate: [ AuthGuard ]
    ,canActivateChild: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CalendarEventRoutingModule { }
