import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Campaign } from "src/app/shared/models/Campaign";
import { AuthService } from "src/app/core/services/auth.service";
import { ServiceRequest } from "src/app/core/models/service-request";

import {
  ServiceConstant,
  ApplicationDateFormatTypeEnum,
} from "src/app/core/models/service-constant";

import { ServiceResponse } from "src/app/core/models/service-response";
// import { BookFilterType } from "src/app/shared/enums/book-filter-type";
// import { BookSortType } from "src/app/shared/enums/book-sort-type";

@Component({
  selector: "app-campaign-list",
  templateUrl: "./campaign-list.component.html",
  styleUrls: ["./campaign-list.component.css"],
})
export class CampaignListComponent implements OnInit {
  @Input() myCampaignIND: boolean;
  applicationDateFormatTypeEnum = ApplicationDateFormatTypeEnum;

  @Output() selectedCampaignChange = new EventEmitter();

  providerId: number;
  campaigns: Array<Campaign>;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    var userData = this.authService.getAuthUser();

    if (userData != null && userData.ProviderID > 0) {
      this.providerId = userData.ProviderID;

      if (this.myCampaignIND) this.getProviderCampaigns();
      else this.getActiveCampaigns();
    } else {
      this.authService.redirectToLoginPage();
      return;
    }
  }

  getActiveCampaigns() {
    var req = new ServiceRequest();
    req.data.sn = ServiceConstant.getActiveCampaignsSN;
    // req.data.p1 = ;
    // req.data.p2 = ;
    // req.data.p3 = ;
    // req.data.p4 = ;
    // req.data.p5 = startRowIndex;
    // req.data.p6 = recordCount;

    this.authService.consumeService(req).subscribe((res) => {
      if (!res) {
        return;
      }

      var serviceResponse = res || ({} as ServiceResponse);

      if (serviceResponse.hasError != true) {
        this.campaigns = serviceResponse.data || ({} as Array<Campaign>);
      }
    });
  }

  getProviderCampaigns() {
    var req = new ServiceRequest();
    req.data.sn = ServiceConstant.getProviderCampaignsSN;
    req.data.p1 = this.providerId;
    // req.data.p2 = sortType;
    // req.data.p3 = filterType;
    // req.data.p4 = startRowIndex;
    // req.data.p5 = recordCount;

    this.authService.consumeService(req).subscribe((res) => {
      if (!res) {
        return;
      }

      var serviceResponse = res || ({} as ServiceResponse);

      if (serviceResponse.hasError != true) {
        this.campaigns = serviceResponse.data || ({} as Array<Campaign>);
      }
    });
  }

  onCampaignClicked(campaign: Campaign) {
    this.selectedCampaignChange.emit(campaign);
  }
}
