import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardAppComponent } from './dashboard-app/dashboard-app.component';
import { HomeComponent } from './components/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { AuthGuard } from '../core/services/auth.guard';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard' ,pathMatch: 'full' },
  {
    path: "", component: DashboardAppComponent,
    children: [
      { path: "dashboard", component: HomeComponent },
      { path: "aboutus", component: AboutUsComponent },
      { path: "aboutUs", component: AboutUsComponent },
      { path: "termsandconditions", component: TermsAndConditionsComponent },
    ]
    ,canActivate: [ AuthGuard ]
    ,canActivateChild: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
