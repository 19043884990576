import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant, ApplicationDateFormatTypeEnum } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';

import { NgbCarousel, NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { APP_CONFIG, IAppConfig } from 'src/app/core/services/app.config';
import { ModalService } from 'src/app/core/modal.service';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { ToolbarService } from 'src/app/core/services/toolbar.service';
import { Subscription } from 'rxjs';
import { Office } from 'src/app/shared/models/Office';
import { ClientReview } from 'src/app/shared/models/ClientReview';
import { OfficeHeaderPicture } from 'src/app/shared/models/OfficeHeaderPicture';
import { OfficeEditComponent } from '../office-edit/office-edit.component';
import { OfficeLocationComponent } from '../office-location/office-location.component';
import { MapLocation } from 'src/app/shared/models/MapLocation';

@Component({
  selector: 'app-office-detail',
  templateUrl: './office-detail.component.html',
  styleUrls: ['./office-detail.component.css']
})
export class OfficeDetailComponent implements OnInit {

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  //=========================
  //=========================

  alert: AlertModel;  
  fileToUpload: File = null;

  officePictures: Array<OfficeHeaderPicture>;
  imagePrefix: string;
  officeId:number;

office: Office;
clientReviews: Array<ClientReview>;

editToolbarButtonSubscription: Subscription;
backButtonClickSubscription: Subscription;

applicationDateFormatTypeEnum=ApplicationDateFormatTypeEnum;

  constructor(private authService: AuthService,
              private route: ActivatedRoute,     
              private modalService: ModalService ,
              private toolbarService: ToolbarService,
              private config: NgbRatingConfig,
  @Inject(APP_CONFIG) private appConfig: IAppConfig,
              private router: Router) { 

                this.imagePrefix = `${this.appConfig.imageEndpoint}/`;
                this.setDefaultOfficePicture();

                config.max = 5;
                config.readonly = true;
              }

setDefaultOfficePicture(){

  if(this.officePictures !=null && this.officePictures.length > 0)
    return;

  var pic = new OfficeHeaderPicture();
  pic.OfficeHeaderPictureID = 0;
  pic.HeaderPicturePath = "/assets/images/no_picture.jpg";

  this.officePictures = new Array<OfficeHeaderPicture>();
  this.officePictures.push(pic);
}

  ngOnInit() {

    this.setupToolbar();

    this.route.params.subscribe(params => {
      
      this.officeId = params.id as number;
      if(isNaN(this.officeId) || this.officeId <=0){
        this.router.navigate(["/office"]);
        return;
      };

      this.getOfficeInfo();
      this.getAllOfficeReviews();

    });

  }

  ngOnDestroy() {

    this.editToolbarButtonSubscription.unsubscribe();
    this.backButtonClickSubscription.unsubscribe();

    this.toolbarService.setBackButtonVisible(false);
    this.toolbarService.setEditButtonVisible(false);
  }

  setupToolbar(){

    this.toolbarService.setTitle('');
    this.toolbarService.setBackButtonVisible(true);
    this.toolbarService.setEditButtonVisible(true);
    this.toolbarService.setNewButtonVisible(false);
    this.toolbarService.setMenuFilterVisibleIND(false);
    this.toolbarService.setMenuSortVisibleIND(false);

    this.editToolbarButtonSubscription = this.toolbarService.editButtonClick$.subscribe(event=>{
      this.editButtonClicked(event);
    });

    this.backButtonClickSubscription = this.toolbarService.backButtonClick$.subscribe(event=>{
      this.backButtonClicked(event);
    }); 
  }


  getOfficeInfo(){

    var serviceRequest= new ServiceRequest();    
    serviceRequest.data.sn = ServiceConstant.getOfficeByOfficeIDSN;
    serviceRequest.data.p1 = this.officeId;

    this.authService.consumeService(serviceRequest).subscribe((response)=>{

      if(!response){        
        return;
      }

      var serviceResponse = response || {} as ServiceResponse;
      if(serviceResponse.hasError != true)
      {
        this.office = serviceResponse.data || {} as Office;

        if(this.office.HeaderPictures.length> 0)
        {
          this.officePictures = this.office.HeaderPictures;
        }
      }
    }); 
  }

  getAllOfficeReviews(){

    var serviceRequest= new ServiceRequest();    
    serviceRequest.data.sn = ServiceConstant.getAllOfficeReviewsSN;
    serviceRequest.data.p1 = this.officeId;
    // serviceRequest.data.p3 = startRowIndex;
    // serviceRequest.data.p4 = recordCount;

    this.authService.consumeService(serviceRequest).subscribe((response)=>{

      if(!response){        
        return;
      }

      var serviceResponse = response || {} as ServiceResponse;
      if(serviceResponse.hasError != true)
      {
        this.clientReviews = serviceResponse.data || {} as Array<ClientReview>;
      }

    }); 

  }

  confirmRemovePicture(officePicture: OfficeHeaderPicture){

    if(officePicture.OfficeHeaderPictureID <= 0)
      return;

    this.modalService.show(
      ConfirmModalComponent,
      {
        title: "سوال", message: "آیا از حذف تصویر اطمینان دارید ؟"
      })
      .then(confirmed => {
        if (confirmed) {

          this.removePicture(officePicture);
        } else {

          //this.confirmResult = "Canceled!";          
        }
      });
  }

  removePicture(officePicture: OfficeHeaderPicture){

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.deleteOfficeHeaderPictureSN;
      serviceRequest.data.p1 = officePicture.OfficeHeaderPictureID;
  
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;
        
        if(srvResponse.hasError != true){

          const itemIndex = this.officePictures.findIndex(obj=> obj["OfficeHeaderPictureID"] == officePicture.OfficeHeaderPictureID);
          if(itemIndex >= 0){
            this.officePictures.splice(itemIndex, 1);
            this.setDefaultOfficePicture();
          }                  
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });   
  }

  onFileSelected(event)
  {

    var fileType = (<File>event.target.files[0]).type;
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    if (!validImageTypes.includes(fileType)) {
        this.alert = {type: "danger" ,message: " عکس انتخاب شده معتبر نمی باشد."};
        return;
    }

    
    this.fileToUpload = <File>event.target.files[0];

    var reader = new FileReader();
    
    reader.onload = function (e) {
    };
    reader.readAsDataURL(<File>event.target.files[0]);
    
    this.uploadOfficePicture();
  }

  uploadOfficePicture()
  {

    var srvRequest= new ServiceRequest();
    srvRequest.data.sn = ServiceConstant.addOfficeHeaderPictureSN;
    srvRequest.data.p1 = this.office.OfficeID;

    this.authService.uploadImageService(srvRequest,this.fileToUpload).subscribe((response)=>{

      if (!response) {

        this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

        return null;
      }

      var srvResponse = response || {} as ServiceResponse;

      if(srvResponse.hasError != true){
        
        var newHeaderPicture = srvResponse.data || {} as OfficeHeaderPicture;

         if(newHeaderPicture.OfficeHeaderPictureID > 0){
           
          

          const itemIndex = this.officePictures.findIndex(obj=> obj["OfficeHeaderPictureID"] == 0);
          if(itemIndex >=0){
            this.officePictures.splice(itemIndex, 1);
          }

          newHeaderPicture.HeaderPicturePath =newHeaderPicture.HeaderPicturePath  + '?' + (new Date()).getTime();;
          this.officePictures.push(newHeaderPicture);
         }

        this.alert = {type: "success" ,message: "اطلاعات با موفقت ثبت گردید"};  
      }
      else if(srvResponse.error.message)
        this.alert = {type: "danger" ,message: srvResponse.error.message};   
      else
        this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
    });
  }

  backButtonClicked(event: Event){

    this.router.navigate(["/office"]);
  }

  editButtonClicked(event: Event){

    this.modalService.show(OfficeEditComponent ,this.office)
      .then(confirmed => {
        if (confirmed) {

          // console.log("confirmed");
          // console.log(newServiceProvider);

          //this.getAllProviderServices();

        } else {

          //this.confirmResult = "Canceled!";    
          console.log("cancel");      
        }
      });
  }

  selectLocation(){

    var location =new MapLocation();
    location.Lat = this.office.Location.Lat;
    location.Lng = this.office.Location.Lng;

    var justReadonly = true;

    this.modalService.show(OfficeLocationComponent,location ,justReadonly)
      .then(confirmed => {
        if (confirmed) {
        } else {    
        }
      });
  }  
}
