import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserProfileAppComponent } from './user-profile-app/user-profile-app.component';
import { IndexComponent } from './components/index/index.component';
import { AuthGuard } from '../core/services/auth.guard';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { EditBankComponent } from './components/edit-bank/edit-bank.component';
import { ActivationRequestComponent } from './components/activation-request/activation-request.component';

const routes: Routes = [
  { path: '', redirectTo: 'userprofile' ,pathMatch: 'full' },
  {
    path: "", component: UserProfileAppComponent,
    children: [
      { path: "profileindex", component: IndexComponent },
      { path: "editprofile", component: EditProfileComponent },
      { path: "editbank", component: EditBankComponent },
      { path: "activationrequest", component: ActivationRequestComponent },
    ]
    ,canActivate: [ AuthGuard ]
    ,canActivateChild: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserProfileRoutingModule { }
