import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CampaignRoutingModule } from './campaign-routing.module';
import { CampaignAppComponent } from './campaign-app/campaign-app.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { CampaignIndexComponent } from './components/campaign-index/campaign-index.component';
import { CampaignListComponent } from './components/campaign-list/campaign-list.component';
import { CampaignDetailComponent } from './components/campaign-detail/campaign-detail.component';

@NgModule({
  declarations: [
    CampaignAppComponent,
    CampaignIndexComponent,
    CampaignListComponent,
    CampaignDetailComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule.forRoot(),
    CampaignRoutingModule
  ]
})
export class CampaignModule { }
