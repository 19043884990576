import { InjectionToken } from "@angular/core";

export let APP_CONFIG = new InjectionToken<string>("app.config");

export interface IAppConfig {
  apiEndpoint: string;
  imageEndpoint: string;
  apiSettingsPath: string;
}

export const AppConfig: IAppConfig = {
  // apiEndpoint: "http://85.15.42.95:8042/provider/api/",
  // imageEndpoint: "http://85.15.42.95:8042/provider/image/",

  // apiEndpoint: "http://172.16.40.15:1002/api/",
  // imageEndpoint: "http://172.16.40.15:1002/image/",

  //  apiEndpoint: "http://appsrv1.beautyles.com:1002/api/",
  //  imageEndpoint: "http://appsrv1.beautyles.com:1002/image/",

  // //test db: 8042
  // apiEndpoint:"https://appsrv1.beautyles.com:8042/provider/api/",
  // imageEndpoint: "https://appsrv1.beautyles.com:8042/provider/image/",

  //test db: 8052
  //apiEndpoint: "https://devapp.beautyles.com:8052/provider/api/",
  //imageEndpoint: "https://devapp.beautyles.com:8052/provider/image/",

  //live db:8052
  apiEndpoint: "https://appsrv1.beautyles.com:8052/provider/api/",
  imageEndpoint: "https://appsrv1.beautyles.com:8052/provider/image/",

  apiSettingsPath: "ApiSettings",
};
