import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgForm } from "@angular/forms";
import { ServiceConstant } from "src/app/core/models/service-constant";
import { ServiceRequestParam } from "src/app/core/models/service-request-param";
import { AuthService } from "src/app/core/services/auth.service";
import { ServiceRequest } from "src/app/core/models/service-request";
import { ServiceResponse } from "src/app/core/models/service-response";
import { PersianNumberService } from "src/app/core/services/persian-number.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  alert: AlertModel;
  serviceRequestParam: ServiceRequestParam = { sn: "", p1: "" };

  error = "";
  returnUrl: string;

  constructor(
    private authService: AuthService,
    private persianNumberService: PersianNumberService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // // reset the login status
    this.authService.logout(false);

    // // get the return url from route parameters
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"];
  }

  submitForm(form: NgForm) {
    this.serviceRequestParam.p1 = this.persianNumberService.toEnglish(
      this.serviceRequestParam.p1
    );
    //console.log(this.serviceRequestParam.p1);

    if (form.valid != true) return;

    var mobileNumber =
      "0098" + this.persianNumberService.toEnglish(this.serviceRequestParam.p1);
    var serviceRequest = new ServiceRequest();
    serviceRequest.data.sn = ServiceConstant.loginServiceSN;
    serviceRequest.data.p1 = mobileNumber;

    this.authService.consumeService(serviceRequest).subscribe((response) => {
      const loginResult = response || ({} as ServiceResponse);

      if (loginResult.hasError != true)
        this.router.navigate(["/confirmlogin"], {
          queryParams: { mobileNumber: serviceRequest.data.p1 },
        });
      else {
        if (loginResult.error.message)
          this.alert = { type: "danger", message: loginResult.error.message };
        else
          this.alert = {
            type: "danger",
            message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید.",
          };
      }
    });
  }
}
