import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProviderCalendarRoutingModule } from './provider-calendar-routing.module';
import { ProviderCalendarAppComponent } from './provider-calendar-app/provider-calendar-app.component';
import { CalendarIndexComponent } from './components/calendar-index/calendar-index.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { CalendarCreateComponent } from './components/calendar-create/calendar-create.component';
import { CalendarEditComponent } from './components/calendar-edit/calendar-edit.component';

@NgModule({
  declarations: [ProviderCalendarAppComponent, CalendarIndexComponent, CalendarCreateComponent, CalendarEditComponent],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule.forRoot(),
    ProviderCalendarRoutingModule
  ]
})
export class ProviderCalendarModule { }
