import { Component, OnInit } from '@angular/core';
import { ProviderService } from 'src/app/shared/models/ProviderService';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceRequest } from 'src/app/core/models/service-request';
import { ServiceConstant } from 'src/app/core/models/service-constant';
import { ServiceResponse } from 'src/app/core/models/service-response';
import { ModalService } from 'src/app/core/modal.service';
import { SelectServiceCategoryComponent } from '../select-service-category/select-service-category.component';
import { ServiceCategory } from 'src/app/shared/models/ServiceCategory';
import { NgForm } from '@angular/forms';
import { PersianNumberService } from 'src/app/core/services/persian-number.service';

@Component({
  selector: 'app-service-edit',
  templateUrl: './service-edit.component.html',
  styleUrls: ['./service-edit.component.css']
})
export class ServiceEditComponent implements OnInit {

  alert: AlertModel;  
  args:ProviderService;

  providerId:number;
  serviceId:number;
  providerService:ProviderService;   
  selectServiceCategory: ServiceCategory;

  constructor(private authService: AuthService,
    private persianNumberService: PersianNumberService,
              private modalService: ModalService) { }

  ngOnInit() {

    this.getProviderServiceInfo();   

    var userData = this.authService.getAuthUser();

    if(userData != null && userData.ProviderID > 0)
    {
      this.providerId = userData.ProviderID; 
    }
    else{

      this.authService.redirectToLoginPage();
      return;
    } 
  }

  getProviderServiceInfo(){

    var serviceRequest= new ServiceRequest();    
    serviceRequest.data.sn = ServiceConstant.getServiceByServiceIDSN;
    serviceRequest.data.p1 = this.args.ServiceID;

    this.authService.consumeService(serviceRequest).subscribe((response)=>{

      if(!response){        
        return;
      }

      var serviceResponse = response || {} as ServiceResponse;
      if(serviceResponse.hasError != true)
      {
        this.providerService = serviceResponse.data || {} as ProviderService;

        this.selectServiceCategory = this.providerService.ServiceCategory;
      }

    }); 

  }  

  close: (val?: any) => void;

  selectCategory(){

    this.modalService.show(SelectServiceCategoryComponent,this.selectServiceCategory)
    .then(confirmed => {
      if (confirmed) {
      } else {
      }
    });
  }  

  submitForm(form:NgForm) {

    if(form.valid != true)
      return;   

    if(isNaN(this.providerId) || this.providerId <=0){
      this.authService.redirectToLoginPage();
      return;
    }

    var normalPrice =parseFloat(this.persianNumberService.toEnglish(String(this.providerService.NormalPrice)));
    var discPrice =parseFloat(this.persianNumberService.toEnglish(String(this.providerService.DiscountedPrice)));
    var durationMinute =parseFloat(this.persianNumberService.toEnglish(String(this.providerService.DurationMinute)));    

    if(discPrice > normalPrice){
      this.alert = {type: "danger" ,message: "قیمت در زمان تخفیف از مبلغ عادی نمی تواند بیشتر باشد."};
      return;
    }

      var serviceRequest= new ServiceRequest();    
      serviceRequest.data.sn = ServiceConstant.updateServiceSN;
      serviceRequest.data.p1 = this.providerService.ServiceID;
      serviceRequest.data.p2 = this.selectServiceCategory.ServiceCategoryID;

      // serviceRequest.data.p3 = this.providerService.NormalPrice;
      // serviceRequest.data.p4 = this.providerService.DiscountedPrice;
      // serviceRequest.data.p5 = this.providerService.DurationMinute;

      serviceRequest.data.p3 = normalPrice;
      serviceRequest.data.p4 = discPrice;
      serviceRequest.data.p5 = durationMinute;

      serviceRequest.data.p6 = this.providerService.Title;
      serviceRequest.data.p7 = this.providerService.Description;
      serviceRequest.data.p8 = this.providerService.IsEnable;

  
      this.authService.consumeService(serviceRequest).subscribe((response)=>{

        if (!response) {

          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};

          return null;
        }

        var srvResponse = response || {} as ServiceResponse;

        if(srvResponse.hasError != true){

          this.providerService.ServiceCategory = this.selectServiceCategory;
          this.args.NormalPrice = this.providerService.NormalPrice;
          Object.assign(this.args ,this.providerService);
          //this.args.ServiceID = 
          this.close(true);
          //this.router.navigate(["/providerserviceindex"]); 
        }
        else if(srvResponse.error.message)
          this.alert = {type: "danger" ,message: srvResponse.error.message};   
        else
          this.alert = {type: "danger" ,message: "انجام عملیات با مشکل مواجه شد ، لطفا مجددا سعی نمائید."};                   
      });     
  } 
}
